.fbtn__container {
  width: 460px;
  min-height: 45px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-image: linear-gradient(180deg, #44a3ff 0%, #002bff 100%);
  border: none;
  border-radius: 5px;
  padding: 5px;
  position: fixed;
  bottom: 1px;
  z-index: 200;
  cursor: pointer;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.loading {
  width: 25px !important;
  height: 25px !important;
  color: white !important;
}
@media (max-width: 480px) {
  .fbtn__container {
    width: 95%;
  }
}
