.db__card {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 15px;
  box-sizing: border-box;
  height: fit-content;
  cursor: pointer;
  padding-bottom: 15px;
  margin: 10px 0px;
  position: relative;
}
.additional__box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}
.additional__box > div {
  width: 80%;
  height: 70%;
  border: 1px solid rgba(128, 128, 128, 0.185);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
