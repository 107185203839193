.wn__upper {
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: .6rem;

  .linksent {
    border: 1px solid #c1c1c1;
    padding: 1rem;
    border-radius: 10px;
    display: flex;
    gap: 0.6rem;
    align-items: center;
    margin-top: 2rem;
    img {
      height: 1.5rem;
    }
    p {
      font-size: 0.8rem;
    }
  }
  .opportunities{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: .5rem;
    .btn {
      max-width: 60%;
      margin-top: 2rem;
    }
    p{
      font-size: .8rem;
      color: gray;
      text-align: center;
      margin: 0 .5rem;
    }
  }
}
.wn__header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin:1rem;
  font-size: 29px;
}
.wn__header__para {
  font-size: 13px;
  color: gray;
}

.wn__footer__para {
  font-size: 13px;
  color: gray;
  margin-top: 2rem;
}
