.backarrow {
  width: 90%;
  height: 70px;
  display: flex;
  align-items: center;
  
}
.backarrow__icon {
  cursor: pointer;
}
