.bankinfo__container {
  padding: 15px;
}
.first__info {
  display: flex;
  font-size: 11px;
}
.bankdata__confirmline {
  font-size: 10px;
  margin-left: 8px;
  margin-top: 1px;
  margin-bottom: 10px;
}
.bankdata__checkbox {
  margin: -14px;
}
.bankdata__confirm {
  margin-top: 20px;
  display: flex;
}

.bankdata__otpmsg {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.623);
  margin-top: 15px;
}
.bankdata__wrongotpmsg {
  font-size: 10px;
  color: red;
}
.incorrect__otp_input {
  outline-color: red !important;
  border-color: red !important;
  color: red !important;
}
.incorrect__otp_input * {
  outline-color: red !important;
  border-color: red !important;
  color: red !important;
}
