.gstart__container {
  height: 87vh;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
  align-items: center;
}
.gstart__header {
  width: 90%;
  align-self: center;
  margin-top: 90px;
}
.gstart__heading {
  font-size: 25px;
  display: flex;
}
.gstart__heading span {
  color: #0766e2;
}
.getstart__media {
  margin: 55px 0;
  margin-bottom: 100px;
}
.getstart__next {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.548);
  font-size: 12px;
}
