.signin__container {
  width: 480px;
  height: 87vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
}
.alternate__link {
  color: #00abff;
}

@media (max-width: 480px) {
  .signin__container {
    width: 100%;
  }
}
