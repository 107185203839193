.dropcard__wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 55px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0);
  box-shadow: 0 10px 35px rgba(0, 0, 0, 0.07);
  padding: 5px 10px;
  box-sizing: border-box;
  transition: 0.3s ease-out;
  margin: 20px 0px;
}
.dropcard__header {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dropcard__header > * {
  width: 60%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.dropcard__icon_img {
  width: 25px;
  height: 25px;
}

.dropcard__icon_sm {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.dc__red_dot {
  width: 14px;
  height: 14px;
  border-radius: 50%;
}
.dw__right {
  width: 30%;
  justify-content: space-evenly;
}
.dropcard__text {
  font-weight: 500;
  color: #111111;
  font-size: 20px;
  padding-left: 10px;
  box-sizing: border-box;
}
.drop__card_body {
  display: none;
  transition: 0.9s ease-out;
}
