.bottom__slide_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 55px;
  padding: 0px 10px;
  box-sizing: border-box;
}
.bsh__text {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}
.bsh__icon {
  color: grey;
  height: 100%;
  display: flex;
  margin-top: 8px;
}
