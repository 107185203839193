.test__signin_btn{
    width: 300px;
    height: 50px;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:10px ;
    background-color: #0b4c77;
    color: white;
}