.corder__screen {
  width: 480px;
  /* height: 87vh; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
}

.corder__body {
  width: 90%;
  flex: 1;
}

.corder__heading {
  font-size: 22px;
}
.corder__headerimg {
  margin-top: 80px;
}
.corder__msg {
  margin: 32px 0px;
  color: gray;
  font-size: 13px;
  font-size: 12px;
  padding: 0 15px;
}
.corder__img {
  width: 40px;
  height: 50px;
}
.corder__text {
  font-size: 14px;
}

.corder__resend__text {
  margin-top: 80px;
  margin-bottom: 10px;
  color: gray;
  width: 100%;
  text-align: center;
  font-size: 12px;
}
.corder__resend__btn {
  margin-top: 80px;
  margin-bottom: 10px;
  text-align: center;
  width: 100%;
  color: grey;
  font-weight: bold;
}
.corder__wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 5px;
}
.corder__box {
  border: none;
  border-bottom: 1px solid gray;
  width: 40px !important;
  font-size: 19px;
}
.corder__box:focus {
  color: green;
  outline: none;
}
@media (max-width: 480px) {
  .corder__screen {
    width: 100%;
  }
}
