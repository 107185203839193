.ordermod__body {
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  /* padding: 3px 11px; */
}
.ordermod__bodytitle {
  background-color: #f5f5f5;
  padding: 8px 10px;
  font-size: 1.06rem;
}
.om_para {
  font-size: 11px;
  margin-top: 3px;
  color: #111111;
  display: flex;
}
.ordermod__inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  padding: 10px;
  display: flex;
}

.ordermod__input .inputbox_upper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.ordermod__validity {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}
.validity__title {
  margin-right: 58px;
  font-size: 15px;
}
.validity__days {
  display: flex;
  padding: 0.3rem 0.4rem;
  border-radius: 7px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  margin-right: 8px;
  outline: none;
}
.validity__years {
  display: flex;
  padding: 0.3rem 0.4rem;
  border-radius: 7px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
}
.validity__years:focus-visible {
  outline: none;
}
.om__validity__para {
  margin-top: 3px;
  color: #000000a6;
  display: flex;
  justify-content: flex-end;
  color: #246afe;
  font-size: 7px;
  margin-right: 48px;
}
.om__validity__img {
  height: 13px;
  width: 10px;
}

.ordermod__valbody {
  font-size: 11px;
  margin-top: 20px;
  margin-bottom: 36px;
  padding: 0 10px;
  color: #5d5d5d;
}
.order__options {
  margin-right: 17px;
}
