.progress__bar {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 75px;
  box-sizing: border-box;
  padding-top: 5px;
  padding: 15px 10px 10px 10px;
  position: relative;
}
.progress__text {
  flex: 1;
  display: flex;
  font-size: 14px;
  position: relative;
}
.progress__wrap {
  width: 100%;
  border-radius: 10px;
  background: rgba(211, 211, 211, 0.63);
  height: 13px;
}
.progress__rate {
  border-radius: 10px;
  background: linear-gradient(
    90deg,
    rgb(5, 141, 5),
    rgb(48, 187, 48),
    rgb(53, 218, 53)
  );
  height: 100%;
}
.pt__demand {
  color: rgb(48, 187, 48);
}
.pt__thresh {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  /* text-align: center; */
}
.thresh__text {
  color: rgb(48, 155, 187);
  height: 30%;
}
.thresh__bar {
  font-weight: bold;
  height: 100%;
  height: 70%;
  font-size: 18px !important;
  display: flex;
  padding-top: 2px;
  box-sizing: border-box;
}
