.oe__upper {
  padding-bottom: 2rem;
}
button {
  cursor: pointer;
}

.instruction {
  font-size: 0.8rem;
  background: #f5f3f3;
  border-radius: 10px;
  padding: 10px 20px;
  box-shadow: 0px 0px 10px rgba(200, 196, 196, 0.1);
  margin-bottom: 20px;
}

.oe__slide__component {
  margin-bottom: 10px;
}

.blurred-box {
  position: relative;
}

.blurred-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
}

.quotes_box {
  display: flex;
  flex-direction: column;
  border-radius: 0.7rem;
  gap: 0.5rem;
  overflow: hidden;
  box-shadow: 0 2px 4px rgb(0 0 0 / 12%);

  &.blurred {
    position: relative;
    filter: blur(4px);
    border: "1px solid #333";
  }

  .quotesheading {
    padding: 0.5rem 1rem;
    background-color: #f5f5f5;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    .heading {
      font-size: 1.1rem;
    }
    .bestbid,
    .bestoffer {
      font-size: 0.8rem;
      .title,
      .detail {
        color: #707070;
      }
    }
  }
  .quoteslist {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    padding: 0.5rem 1rem;

    .bids,
    .offers {
      display: flex;
      gap: 1.5rem;

      p {
        font-size: 0.9rem;
      }
    }
    .line {
      background-color: #f5f5f5;
      width: 2px;
      height: 80px;
    }
    .hea {
      color: #707070;
      font-size: medium !important;
      margin-bottom: 0.4rem;
    }

    .quotesmessage {
      color: #707070;
      font-size: 1rem;
      margin: 1rem;
      text-align: center;

      @media only screen and (max-width: 400px) {
        font-size: 0.8rem;
        margin: 0.8rem 0.5rem;
      }
      @media only screen and (max-width: 350px) {
        font-size: 0.75rem;
        margin: 0.5rem;
      }
    }
  }

  .totalquots {
    background-color: #f5f5f5;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;

    .totalbid,
    .totaloffer {
      display: flex;
      gap: 1rem;

      font-size: 0.8rem;

      .title {
        color: #707070;
      }
      .price {
        font-size: 0.7rem;
      }
    }
  }

  @media only screen and(max-width:400px) {
    .quotesheading {
      .heading {
        font-size: 0.9rem;
      }
      .bestbid,
      .bestoffer {
        font-size: 0.65rem;
      }
    }
    .quoteslist {
      .bids,
      .offers {
        display: flex;
        gap: 1.5rem;

        p {
          font-size: 0.6rem;
        }
      }
      .line {
        background-color: #f5f5f5;
        width: 2px;
        height: 60px;
      }
      .hea {
        color: #707070;
        font-size: 0.7rem !important;
        margin-bottom: 0.2rem;
      }
    }
    .totalquots {
      .totalbid,
      .totaloffer {
        display: flex;
        gap: 1rem;

        font-size: 0.6rem;

        .title {
          color: #707070;
        }
        .price {
          font-size: 0.55rem;
        }
      }
    }
  }
}
