.updemat__header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 36px 0;
}
.updemat__headerpara {
  color: black;
  text-decoration: underline;
}

.align_center {
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
  padding-left: 15px;
}

.demat_pdf {
  display: none;
}
.demat__progress_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}
.demat__progress_loader {
  width: 25px !important;
  height: 25px !important;
  color: rgb(0, 68, 255);
}
