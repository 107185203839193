.ir__upper {
  width: 90%;
  margin: auto;
}

.ir__bottom__header {
  display: flex;
}

.ir__para {
  font-size: 14px;
  margin-left: 3px;
  color: grey;
}
.ir__heading {
  font-size: 28px;
  margin: 20px 0;
}
.ir__heading span {
  color: #0766e2;
}

.ir__upper__para1 {
  font-size: 12px;
  border-radius: 10px;
  /* box-shadow: 0px 0px 5px 0px#0000004a; */
  background-color: rgba(128, 128, 128, 0.097);
  color: gray;
  padding: 15px 12px;
}
.ir__expressDealComponent {
  margin: 15px 0;
}
.ir__watch {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(128, 128, 128, 0.131);
  padding: 10px 0px;
  color: gray;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}
.ir__checkbox__component {
  margin-top: 0px;
  margin-bottom: 7px;
  display: flex;
}
.ir__para2 {
  font-weight: bold;
  font-size: 14px;
  color: black;
}

.ir__para2__link {
  position: absolute;
  bottom: 10px;
  right: 12px;
}

.ir__watch1 {
  font-size: 11px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(128, 128, 128, 0.138);
  padding: 10px 17px;
  color: rgb(128, 128, 128);
  border-radius: 8px;
  margin: 25px 0;
}
.ir__body {
  margin: 1.5rem 0;
  font-size: 12px;
  background-color: rgba(128, 128, 128, 0.13);
  color: gray;
  line-height: 17px;
  padding: 15px 18px;
  border-radius: 12px;
}
.ir__body__para2 {
  margin: 1rem 0;
}
.ir__btn2 {
  border: 1px solid black;
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  margin: 0.5rem 0;
}

.ir__btn2__left {
  width: 4%;
  background-color: red;
  margin-right: 12px;
}
.ir__btn2__right {
  color: red;
  width: 95%;
  padding: 6px 0;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.ir__comp__para1 {
  font-size: 12px;
}
.ir__check1 {
  margin-bottom: 10px;
}
.ir__lower {
  position: fixed;
  bottom: 30%;
  width: 100%;
}
