.modified__order {
  width: 90%;
  margin: auto;
}

.mo__para {
  font-size: 12px;
  color: grey;
  margin-top: 3rem;
  margin-bottom: 9rem;
  padding: 0 20px;
}

.mo__para span {
  color: #0766e2;
}
