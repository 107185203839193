.btn__container {
  width: 100%;
  min-height: 45px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-image: linear-gradient(180deg, #44a3ff 0%, #002bff 100%);
  border: none;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}

.btn__container button:disabled {
  background-image: linear-gradient(180deg, #9d9d9d 0%, #c4c4c6 100%);
  color: white;
  cursor: not-allowed;
}

.loading {
  width: 25px !important;
  height: 25px !important;
  color: white !important;
}
