.screen__loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(192, 186, 186, 0.198);
  z-index: 100000;
  color: rgb(14, 60, 119);
  font-weight: bold;
}
