.funds_container {
  margin: 2rem 1.5rem;

  .success_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    img {
      width: 20%;
    }
  }

  .funds_box {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .funds_title {
      .title {
        font-size: 1.5rem;
        font-weight: bold;
      }
      .dec {
        font-size: 0.9rem;

        span {
          color: #2a6eff;
        }
      }
    }

    .important {
      background-color: #ffdfdf;
      border: 1px solid red;
      border-radius: 0.5rem;
      font-size: small;

      .important_title {
        display: flex;
        justify-content: center;
        text-align: center;

        p {
          background-color: red;
          padding: 0.3rem 2rem;
          color: white;
        }
      }
      .important_data {
        margin: 1rem;
      }
    }

    .funds_data {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin: 1rem 0;

      .MuiAccordion-root {
        border: none;
        border-radius: 8px;
        box-shadow: 0 10px 35px rgb(0 0 0 / 7%);
        padding: 0.3rem;
        &::before {
          opacity: -10;
        }

        .balance_box {
          display: flex;
          align-items: center;
          gap: 1rem;

          .img {
            height: 30px;
            img {
              height: 100%;
            }
          }

          .balance_data {
            .balance {
              display: flex;
              gap: 0.5rem;
              font-size: 1.2rem;
              font-weight: 500;
              align-items: center;

              .cur {
                font-size: 1.5rem;
                font-weight: bold;
              }
            }
            .detail {
              font-size: 0.8rem;
              padding: 0 8px;
            }
          }
        }
        .MuiAccordionDetails-root {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          padding-left: 2rem;

          .balance {
            display: flex;
            gap: 0.5rem;
            font-weight: bold;
          }
          .detail {
            font-size: 0.7rem;
            padding: 0 8px;
          }
        }
      }
    }

    .funds_transfer {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }

  .add_funds_slider {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 0.5rem 1.5rem;

    .heading {
      font-size: 1.5rem;
    }
    .amount {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      .amount_display {
        border: 1px solid #d4d4d4;
        box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.2);
        padding: 10px 20px;
        border-radius: 5px;
        background-color: #efefef;
        font-weight: bold;
      }
      input {
        border: none;
        border-bottom: 2px solid #aaa;
        width: 90%;
        padding: 0.1rem;
        font-size: 1.2rem;
        outline: none;
      }
      .MuiTypography-body1 {
        font-size: 0.9rem;
      }
      .MuiRadio-colorSecondary.Mui-checked {
        color: #44a3ff;
      }
    }
  }

  .MuiButton-label:hover {
    color: #2a6eff;
  }

  .add_bank_slide {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0.5rem 1.5rem;
    .heading {
      p {
        font-size: 1.5rem;
      }
      .detail {
        @media only screen and (max-width: 360px) {
          font-size: 0.9rem;
        }
      }
    }

    .select_bank {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .MuiRadio-colorSecondary.Mui-checked {
        color: #44a3ff;
      }
      .bank_container {
        display: flex;
        flex-direction: column;
        margin-left: 0.1rem;
        font-size: 0.9rem;

        @media only screen and (max-width: 360px) {
          font-size: 0.7rem;
        }

        .address {
          color: #aaa;
        }
      }

      .add_bank {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        color: #44a3ff;
        font-weight: bold;
        cursor: pointer;

        @media only screen and (max-width: 360px) {
          font-size: 0.8rem;
        }
        span {
          padding-bottom: 4px;
          height: 14px;
          width: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 3px solid #44a3ff;
          border-radius: 50rem;
          font-weight: bolder;
          font-size: 1.2rem;

          @media only screen and (max-width: 360px) {
            height: 7px;
            width: 11px;
            border: 2px solid #44a3ff;
            font-size: 1rem;
          }
        }
      }
    }
  }

  .funds_added_slide {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0.5rem 1.5rem;

    .heading {
      p {
        font-size: 1.5rem;
      }
      .detail {
        font-size: 0.8rem;
      }
    }

    .transection_detail {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .title {
        color: #aaa;
        font-size: 1rem;
      }

      .one_div {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        font-size: 0.9rem;
      }
      .two_div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        font-size: 0.9rem;

        .important {
          background-color: #ffdfdf;
          border: 1px solid red;
          border-radius: 0.5rem;
          font-size: 0.6rem;

          .important_title {
            display: flex;
            justify-content: center;
            text-align: center;

            p {
              background-color: red;
              padding: 0.2rem 1.5rem;
              color: white;
            }
          }
          .important_data {
            margin: 0.5rem;
            color: red;
          }
        }
      }
    }

    .note_box {
      border-left: 15px solid #002bff;
      border-top: 1px solid #aaa;
      border-right: 1px solid #aaa;
      border-bottom: 1px solid #aaa;
      border-radius: 1rem;

      padding: 0.4rem 0.7rem;
      font-size: 0.8rem;

      @media only screen and (max-width: 360px) {
        font-size: 0.7rem;
      }
    }

    .notis {
      background-color: #ffdfdf;
      border: 1px solid red;
      border-radius: 0.5rem;
      font-size: 0.8rem;

      .important_title {
        display: flex;
        justify-content: center;
        text-align: center;

        p {
          background-color: red;
          padding: 0.3rem 2rem;
          color: white;
        }
      }
      .important_data {
        margin: 1rem;
        color: red;
      }
      .bank_container {
        display: flex;
        flex-direction: column;
        margin-left: 0.1rem;
        font-size: 0.8rem;
        color: black;
        margin-top: 1rem;

        .address {
          color: #aaa;
        }
      }
    }
  }

  .withdraw_funds_slider {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 0.5rem 1.5rem;

    .heading {
      font-size: 1.5rem;
    }
    .amount {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;

      input {
        border: none;
        border-bottom: 2px solid #aaa;
        width: 90%;
        padding: 0.1rem;
        font-size: 1.2rem;
        outline: none;
      }
      .MuiTypography-body1 {
        font-size: 0.9rem;
      }
      .MuiRadio-colorSecondary.Mui-checked {
        color: #44a3ff;
      }
    }
    .select_bank {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .MuiRadio-colorSecondary.Mui-checked {
        color: #44a3ff;
      }
      .bank_container {
        display: flex;
        flex-direction: column;
        margin-left: 0.1rem;
        font-size: 0.9rem;

        @media only screen and (max-width: 360px) {
          font-size: 0.7rem;
        }

        .address {
          color: #aaa;
        }
      }

      .add_bank {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        color: #44a3ff;
        font-weight: bold;
        cursor: pointer;

        @media only screen and (max-width: 360px) {
          font-size: 0.8rem;
        }
        span {
          padding-bottom: 4px;
          height: 14px;
          width: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 3px solid #44a3ff;
          border-radius: 50rem;
          font-weight: bolder;
          font-size: 1.2rem;

          @media only screen and (max-width: 360px) {
            height: 7px;
            width: 11px;
            border: 2px solid #44a3ff;
            font-size: 1rem;
          }
        }
      }
    }
  }
  .confirmwithdraw_box {
    display: flex;
    flex-direction: column;
    gap: 6rem;

    .heading {
      margin: 0 1rem;

      div {
        font-size: 1.35rem;
      }
      p {
        font-size: 0.8rem;
        margin: 0.6rem 0.5rem;
      }
    }
  }
}
