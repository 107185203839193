.toggle_switch{
    position: relative;
    display: flex;
    height: 16px;
    width: 28px;
    background: #0a07c4;
    border-radius: 99px;
    border: 1 px solid #0d62e2;
    cursor: pointer;
  
}
.toggle_circle{
    height: 11px;
    width: 10px;
    border-radius: 50%;
    position: absolute;
    left: 2px;
    top: 2px;
    background: white;
    box-shadow: 0 0.1em 0.3em rgba(0,0,0,0.3);
    -webkit-transform:all 300ms;
    -webkit-transform:all 300ms;
    -webkit-transition:all 300ms;
}
.toggle_checkbox{
    position: absolute;
    opacity: 0;
}

.toggle_switch .toggle_checkbox:checked + div{
    -webkit-transform:translate3d(130%,0,0);
    -moz-transform:translate3d(130%,0,0);
    transform:translate3d(130%,0,0)
}