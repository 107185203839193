.resources__box {
  display: flex;
  flex-direction: column;
  padding: 5px 0px;
}
.resources__heading {
  font-weight: 600;
  font-size: 22px;
  padding: 5px 0px;
}
.resources__desc {
  color: grey;
  font-size: 14px;
  line-height: 23px;
}
.resources__buttons {
  width: 100%;
  height: 195px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.resources__buttons > * {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
.resources__icon {
  font-size: 70px !important;
  color: lightgray;
}
.resources__text {
  font-size: 21px;
  padding-top: 4px;
  color: #246afe;
}
