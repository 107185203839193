.obligation__container {
  .MuiAccordion-root {
    border: none;
    border-radius: 8px;
    .MuiAccordionSummary-root {
      box-shadow: 0 10px 35px rgb(0 0 0 / 7%);
      padding: 0px 10px;
      border-left: 8px solid blue;
      border-radius: 10px;

      .accor__box {
        display: flex;
        flex-direction: column;
        font-size: 0.67rem;

        .status {
          margin-bottom: 0.5rem;
        }
        .action {
          color: red;
          font-weight: bold;
          font-style: italic;
        }
        .btn {
          color: white;
          background-color: rgba(0, 0, 255, 0.781);
          padding: 3px 8px;
          border-radius: 10px;
          width: 43%;
        }
      }
    }
  }
}
