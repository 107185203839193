.order_list_container {
  .loader {
    height: calc(100vh - 200px);
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    p {
      font-weight: 600;
    }
  }

  .warning {
    img {
      height: 15px;
      width: 15px;
      border-radius: 50px;
      aspect-ratio: 1/1;
    }
    display: flex;
    align-items: center;
    gap: 5px;
    flex-grow: 1;
    margin-top: 10px;
    font-size: 12px;
    color: #fea500;
    width: 100%;
  }
}
