.addbank__container {
  padding: 0 15px;
}
.addbank__header {
  border: 1px solid #dedede;
  display: flex;
  min-height: 40px;
  border-radius: 11px;
  overflow: hidden;
}
.addbank__left {
  width: 3.5%;
  background-color: #002bff;
  margin-right: 5px;
}
.addbank__right {
  width: 95%;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  line-height: 17px;
  padding: 5px 0;
}
.addbank__boldpara {
  font-size: 10px;
  font-weight: 800;
  color: #111111;
}
.addbank__para {
  font-size: 11px;
  color: #111111;
}
.addbank__bodypara {
  margin-top: 25px;
  font-size: 13px;
}
.input__info {
  display: flex;
  font-size: 11px;
  color: #878787;
}
.abank__input {
  margin-bottom: 10px;
}
.title__info {
  color: green;
}
.input__error {
  font-size: 12px;
  color: rgb(197, 28, 28);
}
