.search__modal {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
}
.search__results {
  width: 480px;
  height: 100vh;
  background-color: #f2f2f2;
  padding: 15px;
  overflow-y: auto;
}

.search__results::-webkit-scrollbar {
  display: none;
}

.search__results_data {
  width: 100%;
  height: fit-content;
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  /* padding: 0 10px; */
  align-items: center;
  max-height: calc(100vh - 120px);
}

.search__results_data::-webkit-scrollbar {
  width: 5px;
}

.search__results_data::-webkit-scrollbar-track {
  background: transparent;
}

.search__results_data::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 5px;
}

.search__screen_backarrow {
  font-size: 25px !important;
  padding: 5px;
}
@media (max-width: 480px) {
  .search__results {
    width: 100%;
  }
}
