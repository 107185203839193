.highlights {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.hl__heading {
  font-weight: 600;
  font-size: 22px;
  width: 100%;
  box-sizing: border-box;
  padding-left: 10px;
}
.hl__heading2 {
  font-weight: 700;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
  padding-left: 10px;
}
.highlights__text {
  padding: 10px 10px;

  font-size: 14px;
}
.hl__list {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 10px;
}
.hl__item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
}
.hl__icon {
  padding: 2px 5px;
}
.hl__text {
  color: grey;
  flex: 1;
}
.learn__more_hl {
  font-size: 14px;
  padding-left: 3px;
  color: #00abff;
}
.ck .ck-content {
  color: #808080;
}
.ck .ck-content > p > strong {
  color: black;
  font-size: 14px !important;
}
