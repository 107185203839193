.reqfund__container {
  padding: 0 15px;
}
.reqfund__title {
  margin-bottom: 1rem;
}

.reqfund__title .heading {
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
}
.reqfund__title .detail {
  font-size: 0.8rem;
  color: #aaa;
}

.hdfc-alert {
  padding: 10px;
  border-radius: 5px;
  background-color: #f0c81451;
  border: 2px #f0c814 solid;
  font-size: .8rem;
  margin-bottom: 20px;
  font-weight: 600;
}

.reqfund__header {
  border-radius: 15px;
  box-shadow: 0px 0px 5px 0px#0000004a;
  padding: 11px 19px;
  margin: 15px 0;
  font-size: 11px;
}
.reqfund__req {
  font-size: 11px;
}

.reqfund__component {
  border: 1px solid black;
  display: flex;
  min-height: 35px;
  border-radius: 8px;
  overflow: hidden;
  margin: 25px 11px;
}
.reqfund__left {
  width: 4%;
  background-color: blue;
  margin-right: 12px;
}
.reqfund__right {
  font-size: 14px;
  width: 95%;
  padding: 6px 0;

  align-items: center;
  justify-content: center;
}
.reqfund__confirm {
  margin-left: 0.7rem;
}
.radio__confirm {
  margin-top: 12px;
}
