/* .reqfund__component {
  border: 1px solid black;
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  width: 90%;
}
.reqfund__left {
  width: 4%;
  background-color: blue;
  margin-right: 12px;
}
.reqfund__right {
  width: 95%;
  padding: 6px 0;
  align-items: center;
  justify-content: center;
  font-size: 11px;
} */
.om__comp__container {
  border: 1px solid black;
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  margin: 18px 0;
}
.comp__left {
  width: 4%;
  background-color: blue;
  margin-right: 12px;
}
.comp__right {
  width: 95%;
  padding: 6px 0;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
