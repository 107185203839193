.closure__box {
  display: flex;
  flex-direction: column;
}
.closure__body {
  font-size: 16px;
  font-weight: 500;
  padding: 20px 2px 12px 2px;
}
.closure__bottom {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
}
.closure__bottom_action {
  font-weight: 700;
  font-style: italic;
  color: blue;
  padding: 3px 0px;
}
.closure__details,
.closure__bottom_details {
  background-color: #0f25b6;
  border: none;
  width: auto;
  max-width: 100px;
  color: #fff;
  padding: 5px 10px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 11px;
  font-weight: 600;
  margin-top: 0.7em;
}

.closure__bottom_details:hover {
  background-color: #3a3c46;
}
