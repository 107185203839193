.Terms__container {
  width: 90%;
  margin: auto;
}
.terms__heading {
  font-size: 25px;
}
.terms__heading span {
  color: #0766e2;
}
.terms__header_para {
  font-size: 12px;
  margin: 12px 0;
}
.terms__body {
  font-size: 8px;
  color: grey;
}

.terms__checkbox__component {
  margin-top: 75px;
  margin-bottom: 10px;
}
