.Evar__container {
  width: 480px;
  height: 87vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
}
.Evar__header {
  padding: 0px 20px;
}
/* .signin__img {
  margin-top: 20px;
} */

.Evar__heading {
  font-size: 20px;
  font-weight: 600;
}
.signin__img {
  width: 40px;
  height: 50px;
}

.Evar__para1 {
  font-size: 12px;
  margin-bottom: 100px;
}
.Evar__para2 {
  font-size: 12px;
  display: flex;
  text-align: center;
  flex-direction: column;
}

.ptwo1 {
  margin: 0;
  margin-bottom: 5px;
}
.ptwo2 {
  margin: 0;
}

.body {
  margin: 60px 0;
}
.para2span {
  font-weight: bolder;
  color: grey;
  margin-left: 5px;
}
.resend_email {
  color: grey;
  font-weight: bold;
  margin-right: 2px;
}

@media (max-width: 480px) {
  .Evar__container {
    width: 100%;
  }
}
