.toggle__container {
  min-width: 25px;
  max-width: 25px;
  min-height: 20px;
  max-height: 20px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.toggle__item {
  width: 100%;
  height: 3px;
  background: black;
}
