.funding__wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.funding__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}
.fh__text {
  font-weight: 600 !important;
  font-size: 22px;
  font-weight: 400;
  color: #111111;
}

.fh__icon {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(126, 123, 123);
  background: rgba(211, 211, 211, 0.37);
}
.funding__body {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.funding__item {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  height: 50px;
  margin: 10px 0px;
  box-sizing: border-box;
  padding-left: 5px;
}

.fi__heading {
  font-size: 15px;
  margin-bottom: 5px;
  color: black;
}
.fi__desc {
  font-size: 14px;
  color: #a7a7a7;
}

.bordered {
  border-left: 2px solid rgba(211, 211, 211, 0.616);
}
.funding__list {
  font-size: 13px;
  width: 100%;
  border-radius: 15px;
  background: rgba(241, 241, 241, 0.7);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 15px 10px;
  color: #707070;
  margin-top: 15px;
}
.fl__item {
  margin: 5px 0px;
}
.funding__theading {
  width: 100%;
  padding: 10px 0px;
  font-weight: 600 !important;
  font-size: 22px;
}
