.verify__indentity {
}

.idtycomp__wrapper {
  padding: 10px 15px;
}
.idtycomp__heading {
  font-weight: 600 !important;
  color: black !important;
  font-size: 1.2rem;
  padding: 10px 0px !important;
}
.idtycomp__body {
  padding-bottom: 15px;
}
.idtycon__wrapper {
  width: 100%;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0px 15px;
  margin-bottom: 10px;
  box-sizing: border-box;
}
.iw__country_dropdown {
  width: 85%;
  color: black;
}
.idtycon__number_box {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.idtycon__number_label {
  color: rgba(141, 132, 132, 0.87);
  font-size: 13px;
  padding-bottom: 5px;
}
.idtyinfo__wrapper {
  height: 250px;
  width: 100%;
  margin: 20px 0px;
  padding: 0px 15px;
  box-sizing: border-box;
}
.idtyinfo__wrapper * {
  box-sizing: border-box;
}
.idtyinfo__wrapper > form {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}
.idtyinfo__form_item {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.idtyinfo__input_item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
}
.idtyinfo__input_label {
  font-size: 14px;
  padding-bottom: 10px;
}
.idtyinfo__input {
  border: none;
  border-bottom: 1px solid grey;
  padding-bottom: 5px;
  width: 100%;
  outline: none;
}
.idtyinfo__input_wrapper {
  width: 100%;
  padding: 0px 5px;
}

.idtycin__form_item {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.idtycin__input_item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
}
.idtycin__input_label {
  font-size: 14px;
  padding-bottom: 10px;
}
.idtycin__input {
  border: none;
  border-bottom: 1px solid grey;
  padding-bottom: 5px;
  width: 100%;
  outline: none;
}
.idtycin__input_wrapper {
  width: 100%;
  padding: 0px 5px;
}

.idtycin__wrapper {
  height: 300px;
  width: 100%;
  margin: 20px 0px;
  padding: 0px 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.idtycin__info_box {
  flex: 1;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.idtycin__info_heading {
  font-weight: bold;
}
.idtycin__info_desc {
  color: grey;
}

.idty__text_wrapper {
  height: 250px;
  font-size: 15px;
  width: 100%;
  margin: 20px 0px;
  padding: 0px 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.backdrop {
  /* z-index: 1000 !important; */
}
