.media__box {
  width: 100%;
  margin: 0px;
  padding: 0px;
  border-radius: 10px !important;
  overflow: hidden;
  box-sizing: border-box;
}
.mb__image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.play__screen {
  width: 100%;
  border-radius: 10px;
  position: relative;
}
.placeholder__box_forvideo {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.video__modal {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.yt-video {
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
  border: 0;
  border-radius: 10px;
}

.video__box {
  aspect-ratio: 16/9;
  width: 500px;
  height: auto;
  padding: 0px !important;
  outline: none;
  background-color: black;
  overflow: hidden;
  border-radius: 10px;
}
@media (max-width: 360px) {
  .media__box {
    height: 160px !important;
  }
}
@media (max-width: 480px) {
  .video__box {
    width: 98%;
  }
}
