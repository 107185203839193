.vo__upper {
  width: 90%;
  margin: 0 auto;
  color: gray;
  display: flex;
  flex-direction: column;
}
.vo__heading {
  img{
    height: 3rem;
  }
}
.vo__header__heading {
  font-size: 1.4rem;
  color: black;
  margin: 15px 0;
}
.vo__header__para {
  font-size: 12px;
  margin-bottom: 25px;
}
.vo__userInfo__comp {
  margin-bottom: 3rem;
}
.vo__input1__heading {
  margin-bottom: 7px;
  font-size: 15px;
}
.vo__input {
  margin-bottom: 3rem;
  .MuiFilledInput-input{
    padding: 15px 12px 10px;
  }
  .MuiFilledInput-root{
    background-color: rgba(241, 241, 241, .6);
  }
}
.vo__lower {
  position: fixed;
  max-width: 480px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.vo__container{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
