.signotp_container {
  display: flex;
  justify-content: center;
  .box {
    width: 90%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .otp-input {
      border-radius: 5px;
      border: 1px solid #ccc;
      display: flex;
      height: 40px;
      gap: 10px;
      align-items: center;
      padding: 5px 5px 5px 15px;
      input {
        border: none;
        width: 100%;
        outline: none;
        &:disabled {
          opacity: 0.5;
        }
      }
      .paste-btn {
        display: none;
        align-items: center;
        justify-content: center;
        height: 35px;
        aspect-ratio: 1/1;
        background-image: linear-gradient(180deg, #44a3ff, #002bff);
        color: #fff;
        border-radius: 5px;
        &:disabled {
          opacity: 0.5;
          background-color: #ccc !important;
          background-image: none;
          color: #333 !important;
        }
        @media (max-width: 500px) {
          display: flex;
        }
      }
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .data {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      gap: 5rem;
      margin-top: 6rem;
      .lock {
        img {
          width: 40px;
        }
        .title {
          margin-top: 0.5rem;
          font-size: 1.4rem;

          span {
            color: blue;
          }
        }
      }
      .input {
        color: gray;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        font-size: 1.1rem;
      }

      .otpdetail {
        color: gray;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-bottom: 20px;
      }
    }
    .btn {
      max-width: 480px;
      width: 100%;
      display: none;
      position: fixed;
      max-width: 480px;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      @media (min-width: 500px) {
        display: block;
      }
    }
  }
}
