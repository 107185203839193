.up__upper {
  width: 90%;
  margin: auto;
}

.up__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 35px 0;
}

.up__para1 {
  font-size: 19px;
}
.up__para2 {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 35px;
}
