.tq__confirm {
  display: flex;
}
.tq__checkbox {
  margin: -11px;
  margin-right: -15px;
}
.tq__confirmline {
  font-size: 12px;
  margin: 2px 8px;
  color: #000000bf;
}
