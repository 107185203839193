.po__container {
  .per {
    border-bottom: 1px solid blue;
    color: blue;
    input {
      color: blue;
      text-align: center;
      border: none;
      width: 38px;
      outline: none;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .pererr {
    margin-left: 0.2rem;
    font-size: 0.6rem;

    @media only screen and (max-width: 400px) {
      font-size: 0.4rem;
    }
  }
}
.po__upper {
  width: 90%;
  margin: auto;
}
.po__heading {
  font-size: 23px;
  margin-top: 17px;
}
.po__paras {
  font-size: 12px;
  margin: 6px 0;
}
.po__checkbox__para {
  margin-top: 0.4rem;
  font-size: 8.5px;
  width: 60%;
  color: grey;
  p {
    font-weight: bold;
    color: black;
    font-size: 0.6rem;
    margin: 0.1rem 0;
  }
}
.po__checkbox__component {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.po__footer__para1 {
  font-size: 10px;
  margin: 20px 0;
  background-color: rgba(128, 128, 128, 0.248);
  padding: 9px 8px;
  border-radius: 10px;
  color: gray;
}
.po__footer__para2 {
  font-size: 10px;
  text-align: center;
  margin-bottom: 8px;
}
.po__lower {
  position: fixed;
  max-width: 480px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}
