.signup__container {
  width: 90%;
  /* height: 87vh; */
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
  align-items: center;
  /* padding-top: 20px; */
}
.signup__header {
  /* padding: 0px 20px; */
  width: 100%;
  align-self: center;
}

.signin__img {
  width: 40px;
  height: 50px;
}

.signup__para {
  display: flex;
  font-size: 11px;
  margin-top: -10px;
  margin-bottom: 45px;
}

.signup__input {
  margin-bottom: 35px;
}
.signup__or {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  margin-bottom: 25px;
  color: rgba(0, 0, 0, 0.76);
}

.signup__google {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.342);
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 100%;
  padding: 5px 0px;
}
.google__text {
  color: rgba(0, 0, 0, 0.685);
}

.google__img {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.signup__body {
  margin: 60px 0;
}
/* @media (max-width: 480px) {
  .signup__container {
    width: 100%;
  }
} */
