.search__data {
  display: flex;
  width: 98%;
  min-height: 100px;
  background: #fff;
  box-sizing: border-box;
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
}
.search__data_left {
  display: flex;
  flex-direction: column;
  flex: 2.3;
  justify-content: space-evenly;
}
.search__data_right {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  flex: 1;
  height: 100%;
  padding-top: 5px;
}
.search__data_title {
  font-weight: bold;
  font-size: 15px;
}
.search__data_subtitle {
  color: grey;
  font-size: 12px;
  width: 100%;
}
.search__data_closingsoon {
  color: red;
  font-size: 12px;
}
.search__data_right {
  font-size: 14px;
}
.sd__freshround_icon {
  font-size: 14px !important;
  margin-left: 5px;
}
.search__data_badge {
  font-size: 10px;
  width: 100%;
  height: 22px;
  border-radius: 10px;
  background-color: lightgray;
  color: grey;
  display: flex;
  justify-content: center;
  align-items: center;
}
