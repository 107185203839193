.card__content {
  padding: 6px 8px 15px 8px;
  position: relative;
}
.card__header {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  padding: 8px 0px;
  text-decoration: none;
  color: black;
  cursor: pointer;
}
.ch__image {
  width: 80px;
  height: 100%;
  border-radius: 15px;
}
.header__info {
  flex: 1;
  display: flex;
  height: 100%;
  text-align: flex-start;
  padding: 4px 0px;
  box-sizing: border-box;
}
.header__info > .info__left {
  display: flex;
  width: 60%;
  flex-direction: column;
  /* justify-content: space-between; */
  height: 100%;
  box-sizing: border-box;
  padding: 0px 5px;
  font-size: 14px;
  position: relative;
}
.header__info > .info__right {
  display: flex;
  width: 40%;
  flex-direction: column;
  height: 100%;
}
.hi__title {
  font-weight: bold;
  position: relative;
  overflow-x: hidden;
  width: 90%;
  font-size: 13px;
  overflow-y: hidden;
  height: 22px;
}
.hi__title > span {
  width: 100%;
  font-weight: 500;
  color: #111111;
  max-width: 1200px;
  position: absolute;
  overflow-x: scroll;
  scrollbar-width: none;
  overflow-y: hidden;
  white-space: nowrap;
}
.hi__title span::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.hi__desc {
  color: #8a8a8a;
  font-size: 11px;
}
.hi__badge1 {
  font-size: 11px;
  height: 22px;
  color: rgb(165, 131, 67);
  background: rgba(228, 177, 100, 0.288);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  width: 105px;
}
.hi__badge2 {
  font-size: 11px;
  height: 22px;
  color: rgb(126, 123, 123);
  background: rgba(211, 211, 211, 0.541);
  border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 5px;
  box-sizing: border-box;
  width: 106px;
  padding: 0 0.7rem;
}
.hi__badge2 > * {
  transform: scaleY(45deg);
}
.dbc__body {
  width: 100%;
  min-height: 100px;
  display: flex;
  color: rgb(167, 161, 161);
}
.dbc__body > .body__left {
  width: 65%;
  text-align: justify;
  font-size: 14px;
}
.card__body_link {
  font-size: 14px;
  color: inherit;
}
.dbc__body > .body__right {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  box-sizing: border-box;
}
.closing__soon {
  color: red;
  display: flex;
  font-size: 13px;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
  width: 100%;
}
.closing__soon > * {
  margin-left: 5px;
}
.br__icons {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.dbc__body > .body__right > .br__icons > span {
  width: 45px;
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(126, 123, 123);
  background-color: #f1f1f1;
  margin: 0px 5px 0px 15px;
}
.dbc__body .learn__more {
  margin-left: 8px;
  color: #00abff;
}
@media (min-width: 470px) {
  .hi__badge1,
  .hi__badge2 {
    font-size: 11px;
    width: 140px;
  }
}
@media (min-width: 430px) and (max-width: 480px) {
  .hi__badge1,
  .hi__badge2 {
    font-size: 11px;
    width: 120px;
  }
}
@media (max-width: 430px) {
  .dbc__body > .body__left {
    width: 62%;
  }
  .dbc__body > .body__right {
    width: 38%;
  }
  .header__info > .info__left {
    width: 60%;
  }
  .header__info > .info__right {
    width: 40%;
  }
}
@media (max-width: 370px) {
  .hi__badge1,
  .hi__badge2 {
    font-size: 9px;
    margin-left: -16px;
  }
  .header__info > .info__left {
    font-size: 11px;
  }
  .dbc__body > .body__right > .br__icons > span {
    height: 40px;
  }
}
