.co__upper {
  width: 90%;
  margin: auto;
}

.co__bottom__header {
  display: flex;
}

.co__para {
  font-size: 14px;
  margin-left: 3px;
  color: grey;
}
.co__heading {
  font-size: 23px;
  margin: 20px 0;
  span {
    color: #0766e2;
  }
}

.co__upper__para1 {
  font-size: 13px;
  line-height: 1.5rem;
  border-radius: 15px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  padding: 15px 12px;

  .extra_box {
    color: black;
    font-weight: 500;
    line-height: 1.3rem;
  }
}
.co__expressDealComponent {
  margin: 30px 0;
}
.co__watch {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(241, 241, 241, 0.6);
  padding: 10px 0px;
  color: gray;
  border-radius: 8px;
  margin-bottom: 65px;
}
.co__checkbox__component {
  margin-top: 0px;
  margin-bottom: 7px;
  display: flex;
}
.co__container {
  .success_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    img {
      width: 20%;
    }
  }

  .funds_added_slide {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0.5rem 1.5rem;

    .heading {
      p {
        font-size: 1.5rem;
      }
      .detail {
        font-size: 0.8rem;
      }
    }

    .transection_detail {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .title {
        color: #aaa;
        font-size: 1rem;
      }

      .one_div {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        font-size: 0.9rem;
      }
      .two_div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        font-size: 0.9rem;

        .important {
          background-color: #ffdfdf;
          border: 1px solid red;
          border-radius: 0.5rem;
          font-size: 0.6rem;

          .important_title {
            display: flex;
            justify-content: center;
            text-align: center;

            p {
              background-color: red;
              padding: 0.2rem 1.5rem;
              color: white;
            }
          }
          .important_data {
            margin: 0.5rem;
            color: red;
          }
        }
      }
    }

    .note_box {
      border-left: 15px solid #002bff;
      border-top: 1px solid #aaa;
      border-right: 1px solid #aaa;
      border-bottom: 1px solid #aaa;
      border-radius: 1rem;

      padding: 0.4rem 0.7rem;
      font-size: 0.8rem;

      @media only screen and (max-width: 360px) {
        font-size: 0.7rem;
      }
    }

    .notis {
      background-color: #ffdfdf;
      border: 1px solid red;
      border-radius: 0.5rem;
      font-size: 0.8rem;

      .important_title {
        display: flex;
        justify-content: center;
        text-align: center;

        p {
          background-color: red;
          padding: 0.3rem 2rem;
          color: white;
        }
      }
      .important_data {
        margin: 1rem;
        color: red;
      }
      .bank_container {
        display: flex;
        flex-direction: column;
        margin-left: 0.1rem;
        font-size: 0.8rem;
        color: black;
        margin-top: 1rem;

        .address {
          color: #aaa;
        }
      }
    }
  }
}
.extra_box2 {
  background-color: rgba(241, 241, 241, 0.6);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
  border-radius: 15px;
  padding: 0.8rem 1rem;
  font-size: 0.75rem;
  margin-bottom: 30px;
  span {
    color: blue;
  }
}

.onemorething_container {
  .box {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    padding: 0 1rem;
    .heading {
      display: flex;
      flex-direction: column;
      // gap: .5rem;

      p {
        font-size: 1.3rem;
      }
    }
  }
}

.add_bank_slide {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0.5rem 1.5rem;
  .heading {
    p {
      font-size: 1.5rem;
    }
    .detail {
      @media only screen and (max-width: 360px) {
        font-size: 0.9rem;
      }
    }
  }
}

// not found order
.not-found {
  display: flex;
  height: calc(100vh - 51px);
  width: 100%;
  align-items: center;
  justify-content: center;
  .box {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 30px;
    img.warning-logo {
      height: 150px;
      width: 150px;
      object-fit: cover;
    }
    h2 {
      text-align: center;
    }
    .problem {
      color: red;
      font-weight: 600;
    }
    a {
      background: #0766e2;
      background-image: linear-gradient(180deg, #44a3ff 0%, #002bff 100%);
      color: #ffffff;
      border-radius: 50px;
      font-weight: 700;
      padding: 10px 25px;
      transition: all 0.2s ease;
      &:hover {
        background-image: none;
        background-color: #3e8cf3;
      }
    }
  }
}

.margin-error-container {
  min-height: 200px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}
