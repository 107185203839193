.potp__container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}

.potp__upper {
  width: 85%;
  margin: 0 auto;
}
.potp__back {
  margin-top: 2rem;
  width: 25px;
  height: 25px;
}
.potp__header {
  margin-top: 4rem;
}
.ptop__header__img {
  height: 50px;
  width: 50px;
}
.potpo__hader__para {
  font-size: 26px;
  span {
    color: #0766e2;
  }
}

.potp__body {
  margin-top: 3rem;
  margin-bottom: 7rem;
}
.potp__body__para {
  font-size: 12px;
  margin-bottom: 1rem;
  color: gray;
}
.potp__bottom {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  color: gray;
  margin-bottom: 5rem;
}
.potp__resend {
  color: black;
  font-weight: 600;
}
.confirmorder_box {
  display: flex;
  flex-direction: column;
  gap: 6rem;

  .heading {
    margin: 0 1rem;

    div {
      font-size: 1.35rem;
    }
    p {
      font-size: 0.8rem;
      margin: 0.6rem 0.5rem;
    }
  }
  button {
    color: white;
    background-image: linear-gradient(180deg, #d99797 0%, #ff0000 100%);
    border: none;
    padding: 5px;
    min-height: 45px !important;
    width: 100%;
    border-radius: 0.55rem;
  }
}
