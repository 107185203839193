.fundstransfer_container {
  margin: 2rem 1.5rem;

  .fundstransfer_box {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .fundstransfer_title {
      display: flex;
      justify-content: space-between;
      .title {
        font-size: 1.5rem;
        font-weight: bold;

        @media only screen and (max-width: 400px) {
          font-size: 1.3rem;
        }
      }
      .back {
        display: flex;
        font-size: 0.9rem;
        align-items: center;
        justify-content: center;
        gap: 0.3rem;
        cursor: pointer;
        span {
          padding-bottom: 0.1rem;
        }
        img {
          height: 20px;
        }
      }
    }

    .dec {
      padding: 0.5rem;
      border: 1px solid #246afe;
      font-size: 0.8rem;
      border-radius: 1rem;

      span {
        font-size: 1rem;
        font-weight: bold;
      }
    }

    .fundstransfer_data {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin: 1rem 0;
      padding: 10px;
      max-height: 450px;
      border-radius: 20px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      overflow-y: scroll;
      overflow-x: none;
      // uncomment to style scrollbar
      &::-webkit-scrollbar {
        width: 5px;
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        width: 5px;
        background: #ccc;
        border-radius: 50px;
      }
      .MuiAccordion-root {
        border: none;
        border-left: 10px solid #002bff;
        border-radius: 8px;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.301);
        padding: 0.3rem;
        &::before {
          opacity: -10;
        }

        .balance_box {
          .date {
            color: #aaa;
            font-size: 0.8rem;
            padding: 0 5px;

            @media only screen and (max-width: 400px) {
              font-size: 0.7rem;
            }
          }
          .balance_data {
            .balance {
              display: flex;
              align-items: center;
              gap: 0.5rem;
              font-size: 1.2rem;
              font-weight: bold;

              @media only screen and (max-width: 400px) {
                font-size: 1rem;
              }

              .cur {
                font-size: 1.5rem;
                @media only screen and (max-width: 400px) {
                  font-size: 1.3rem;
                }
              }
            }
            .detail {
              font-size: 0.8rem;
              color: #aaa;
              padding: 0 5px;

              @media only screen and (max-width: 400px) {
                font-size: 0.7rem;
              }
            }
          }
          .success_box {
            position: absolute;
            left: 60%;
            top: 35%;
            display: flex;
            gap: 0.5rem;
            align-items: center;
            color: #2eb139;
            font-size: 0.95rem;

            @media only screen and (max-width: 400px) {
              font-size: 0.75rem;
              left: 55%;
            }

            span {
              background-color: #2eb139;
              height: 13px;
              width: 13px;
              border-radius: 50rem;

              @media only screen and (max-width: 400px) {
                height: 10px;
                width: 10px;
              }
            }
          }
        }
        .MuiAccordionDetails-root {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          padding-left: 2rem;

          .balance_detail {
            color: #aaa;
            font-size: 0.7rem;

            @media only screen and (max-width: 400px) {
              font-size: 0.65rem;
            }

            .err_note {
              margin-top: 1rem;
              color: black;
              font-size: 0.85rem;

              @media only screen and (max-width: 400px) {
                font-size: 0.7rem;
              }
            }
          }
        }
      }
    }
  }
}
