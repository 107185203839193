.confirm__box_backdrop {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 500;
  top: 0;
  left: 0;
  background-color: rgba(85, 88, 88, 0.157);
}
.confirm__box_dialog {
  background-color: white;
  width: 450px;
  height: 180px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  /* box-shadow: 0px 0px 5px 6px rgba(153, 151, 151, 0.198); */
}
.confirm__box_icon {
  font-size: 40px !important;
  color: rgba(23, 82, 192, 0.719);
}
.confirm__box_text {
  font-size: 18px;
  font-weight: 600;
  color: grey;
  width: 100%;
  text-align: center;
}
.confirm__box_btns {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}
.cbb__yes {
  background-color: rgb(48, 184, 48);
  color: white;
  width: 70px;
  height: 30px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 3px;
  cursor: pointer;
}
.cbb__no {
  background-color: rgb(197, 30, 30);
  color: white;
  width: 70px;
  height: 30px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 3px;
  cursor: pointer;
}
@media (max-width: 480px) {
  .confirm__box_dialog {
    width: 90%;
  }
}
