.raise__request {
  width: 100%;
  min-height: 350px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
}
.rrq__heading {
  color: grey;
  font-size: 20px;
}
.rrq__btn {
  width: 70%;
  height: 50px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: rgb(0, 87, 250);
  border-radius: 15px;
}
.rrq__desc1 {
  color: lightgray;
  font-size: 14px;
  padding: 0px 15%;
}
.rrq__desc2 {
  color: lightgray;
  font-size: 14px;
  padding: 0px 5%;
}
