.details {
  width: 480px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px 10px;
  padding-bottom: 100px;
  overflow-y: scroll;
}
.details::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.dashboard__header_details {
  width: 480px;
  position: fixed;
  top: 0px;
  z-index: 1000;
  background-color: white;
}
.dummy__header_details {
  height: 60px;
  display: contents;
}
.details__body {
  margin-top: 50px;
}

@media (max-width: 480px) {
  .details {
    width: 100%;
  }
  .dashboard__header_details {
    width: 100%;
  }
}
