.vom__upper {
  width: 90%;
  margin: auto;
}

.vom__header__para1 {
  font-size: 24px;
  color: gray;
}

.vom__header__heading {
  margin-top: 8px;
  font-size: 24px;
}
.vom__header__heading span {
  color: #0766e2;
}
.vom__para2 {
  margin-top: 3px;
  font-size: 13px;
  color: gray;
}

.vom__body__card1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 3rem;
}
.vom__card1__para1 {
  font-size: 13px;
  color: gray;
  font-weight: 500;
}
.vom__checkbox__com {
  margin: 2rem 0;
}
