.app__bar {
  width: 100%;
  height: 45px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 5px;
  padding-right: 5px;
}
.search__bar {
  flex: 1;
  margin: 0px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search__box {
  flex: 1;
  margin: 0px 5px;
  border-bottom: 2px solid rgba(105, 100, 100, 0.6);
  display: flex;
}
.search__input {
  width: 100%;
  border: none;
  font-size: 20px;
  outline: none;
}
.barf__icon {
  position: relative;
  box-sizing: border-box;
  /* padding-right: 5px; */
}
.barf__icon > img {
  width: 30px;
  height: 30px;
}
.notif__count {
  width: 16px;
  height: 16px;
  position: absolute;
  font-size: 9px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -1px;
  right: -1px;
  background-color: #44a3ff;
  border-radius: 50%;
}
.ab__toggle_icon {
  width: 30px;
  height: 30px;
}
