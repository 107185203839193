.sendlink_container{
    display: flex;
    flex-direction: column;
    gap: 3rem;

    .box{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin: 0 1rem;

        .heading{
            font-size: 1.3rem;
        }
        .detail{
            font-size: .9rem;
            margin: 0 .4rem;
        }
    }
}