.ms__container {
  width: 90%;
  margin: 1rem auto;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  .ms__heading {
    font-size: 1.3rem;
  }
  .setting_list {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;

    .fee {
      display: flex;
      flex-direction: column;
      gap: 0.2rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.698);
      padding: 0.2rem;
      padding-bottom: 0.4rem;
      .heading {
        font-size: large;
      }
      .detail {
        color: #aaa;
        font-size: small;
      }
    }
  }
}
