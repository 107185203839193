.share__screen_modal {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

button {
  border: 0;
}

.share__screen_box {
  width: 400px;
  height: fit-content;
  padding: 30px 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background-color: white;
  border-radius: 5px;
  row-gap: 10px;
}
.share__screen_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0px 0px 5px 3px rgba(82, 79, 79, 0.178);
  margin-left: 20px;
}
.copytoclipboard__icon {
  font-size: 30px !important;
}
.sscs__text {
  font-size: 11px;
  font-weight: bold;
  color: #007aff;
  padding-bottom: 4px;
  /* display: none; */
}

.ss__clipboard_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
}
@media (max-width: 480px) {
  .share__screen_box {
    width: 80%;
  }
}
