.ep__container {
  /* margin: 0 15px; */
  border-radius: 13px;
  /* overflow: hidden; */
  /* background-color: rgba(29, 20, 20, 0.124); */
  padding-bottom: 10px !important;
  box-sizing: border-box;
  /* margin: -3px; */
}

.ep__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: linear-gradient(180deg, #44a3ff 0%, #002bff 100%);
  color: white;
  height: 30px;
  padding: 11px 15px;
}

.ep__header__img {
  height: 70%;
  display: flex;
}
.ep__body {
  font-size: 14.3px;
  height: 230px;
  padding: 22px 15px;
  color: gray;
  background-color: white;
  overflow: auto;
}
