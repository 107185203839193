.search__page {
  width: 480px;
  height: 87vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px 10px;
  box-sizing: border-box;
}
@media (max-width: 480px) {
  .search__page {
    width: 100%;
  }
}
