.up__upper {
  width: 90%;
  margin: auto;
}
.up__header {
  display: flex;
}
.up__header__img {
  width: 70px;
  height: 55px;
}
.up__header__para {
  font-size: 12px;
  color: gray;
  margin-top: 5px;
}

.up__header__body {
  width: 93%;
  margin: auto;
  margin-top: 2rem;
}
.up__body__para {
  margin-bottom: 5px;
  font-size: 12px;
  color: gray;
}
.up__chkbox__comp {
  display: flex;
  margin: 2rem 0;
}
