.psignup__wrap {
  max-width: 480px;
}
.psignup__container {
  padding: 40px 20px;
  box-sizing: border-box;
  margin-bottom: 0;
}
.header__para {
  font-size: 12px;
  color: #0000009c;
  margin: 0;
  height: 16.5px;
}
.bottom__sticky_btnwrapper {
  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 480px;
}
.header__heading {
  display: flex;
  flex-direction: column;
  font-size: 25px;
}
.inputs__heading {
  font-size: 12px;
  color: #0000009c;
  margin: 15px 0;
}
.persons__info {
  display: flex;
}
.psignup__confirmbox {
  margin-top: 9px;
  display: flex;
}
.Psignup__confirmline {
  font-size: 9px;
  margin-left: 8px;
  margin-top: 1px;
  margin-bottom: 10px;
}
.inputs__head {
  font-size: 12px;
  color: #0000009c;
  margin-bottom: 0;
  margin-top: 14px;
}
.psignup__footer {
  display: flex;
  margin-top: 14px;
  justify-content: center;
  margin-bottom: 5px;
}
.psignup__switch {
  display: flex;
}
.psignup__toggle {
  display: flex;
  margin: 10px 0;
}
.psignup__checkbox {
  margin: -14px;
}
.psignup__p1 {
  margin-left: 8px;
  font-size: 14px;
}
.psignup__slash {
  margin: 0 4px;
  font-size: 14px;
}
.psignup__p2 {
  font-size: 14px;
}
.psignup__confirm {
  margin-top: 20px;
  display: flex;
}
.psignup__confirmline {
  font-size: 9px;
  margin-left: 8px;
  margin-top: 1px;
  margin-bottom: 10px;
}
.psignup__checkbox__component {
  margin-top: 5px;
}
