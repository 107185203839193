.bottom__slide {
  width: 480px;
  height: 400px;
  background-color: white;
  position: fixed;
  bottom: 0;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  z-index: 30000;
  height: fit-content;
  box-sizing: border-box;
  overflow: hidden;
  left: 20;
}
.open__bslide {
  animation: bottomup 0.6s ease-out;
}
.close__bslide {
  animation: closebslide 0.6s ease-out farwards;
}

.backdrop {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  height: 100vh;
  display: flex;
  left: 0;
  top: 0;
  justify-content: center;
  z-index: 30000;
}
@keyframes bottomup {
  from {
    transform: translateY(80vh);
  }
  to {
    transform: translateY(0vh);
  }
}
@keyframes closebslide {
  from {
    transform: translateY(0vh);
  }
  to {
    transform: translateY(80vh);
  }
}
@media (max-width: 480px) {
  .bottom__slide {
    width: 100%;
  }
}

.bottom__slide_close {
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  box-sizing: border-box;
}
.bsh__text {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}
.bsh__icon {
  color: grey;
  height: 100%;
  display: flex;
  margin-top: 8px;
}
