.reg__upper {
  width: 90%;
  margin: auto;
  color: gray;

  .extraline{
    color: green;
    margin-top: 1rem;
    font-size: .8rem;
  }
}
.reg__container{
  display: flex;
  flex-direction: column;
  gap: 5rem;
}

.reg__header {
  margin-top: 15px;
  font-size: 11px;
  display: flex;
  text-align: center;
  background-color: rgba(128, 128, 128, 0.172);
  padding: .5rem .2rem;
  color: gray;
  border-radius: 8px;
  margin-bottom: 10px;
}

.reg__header__border {
  border-bottom: 1px solid rgba(128, 128, 128, 0.411);
}

.reg__input1__heading {
  font-size: 14px;
  margin: 20px 0;
}
.reg__input1 {
  position: relative;
  display: flex;
}

.reg__input1__btn {
  min-height: 45px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-image: linear-gradient(180deg, #44a3ff 0%, #002bff 100%);
  border: none;
  border-radius: 10px;
  padding: 15px 20px;
  position: absolute;
  right: 10px;
  bottom: -4px;
}
.reg__client__name {
  display: flex;
  justify-content: space-between;
  p{
    background-color: #f5f5f5;
    width: 40%;
    padding: .5rem;
  }
}
.MuiAutocomplete-popper{
  z-index: 99999 !important;
}