.action__alert_wrapper {
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: rgba(60, 61, 60, 0.171);
  z-index: 9000000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
}
.action__alert {
  width: 470px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 160px;
  background-color: white;
  border-radius: 10px;
  position: fixed;
  top: 10%;
  margin: auto;
  z-index: 9000000;
}
.action__alert_close {
  position: absolute;
  right: 15px;
  top: 10px;
}
.action__alert_text {
  color: grey;
  padding: 25px 0px;
  box-sizing: border-box;
}
.action__alert_btn {
  min-height: 30px;
  padding: 0px 20px;
  padding-bottom: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background-color: rgb(22, 158, 40);
  color: white;
}
@media (max-width: 480px) {
  .action__alert {
    width: 90%;
  }
}
