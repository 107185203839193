.register_container {
  display: flex;
  justify-content: center;
  .box {
    width: 90%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .email-input {
      border-radius: 5px;
      border: 1px solid #ccc;
      display: flex;
      height: 40px;
      gap: 10px;
      align-items: center;
      padding: 5px 5px 5px 15px;
      input {
        border: none;
        width: 100%;
        outline: none;
        &:disabled {
          opacity: 0.5;
        }
      }
      .send-btn {
        display: none;
        align-items: center;
        justify-content: center;
        height: 35px;
        aspect-ratio: 1/1;
        background-image: linear-gradient(180deg, #44a3ff, #002bff);
        color: #fff;
        border-radius: 5px;
        &:disabled {
          opacity: 0.5;
          background-color: #ccc !important;
          background-image: none;
          color: #333 !important;
        }
        @media (max-width: 500px) {
          display: flex;
        }
      }
    }
    .data {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      gap: 5rem;
      margin-top: 6rem;
      .email {
        img {
          width: 60px;
        }
        .title {
          font-size: 1.4rem;
        }
      }
      .input {
        color: gray;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        font-size: 1.1rem;
        .textfield {
          width: 100%;
        }
      }

      .signup__google {
        display: flex;
        border: 1px solid rgba(0, 0, 0, 0.342);
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        width: 100%;
        padding: 5px 0px;

        .google__text {
          color: gray;
        }

        .google__img {
          width: 30px;
          height: 30px;
          margin-right: 20px;
        }
      }
    }
  }
  .btn {
    max-width: 480px;
    margin: 0 auto;
    width: 100%;
    display: none;
    position: fixed;
    max-width: 480px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    @media (min-width: 500px) {
      display: block;
    }
  }
}
