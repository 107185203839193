.accor__container {
  width: 90%;
  margin: auto;
}
.accor__expanded__view {
  width: 100%;
  font-size: 13px;
  button.btn {
    border: none;
    background: transparent;
    color: #002bff;
    cursor: pointer;
  }
  @media screen and (max-width: 480px) {
    font-size: 13px;
  }
  @media screen and (max-width: 410px) {
    font-size: 10px;
  }
}
.ev__body2__link {
  right: 0;
  position: absolute;
  top: 30px;
}
.sa__icon {
  width: 15px;
  height: 15px;
}
.sa__header {
  display: flex;
}
.sa__header {
  display: flex;
  justify-content: space-between;
  margin: 15px 20px;
}
.accor__heading {
  color: #002bff;
  font-size: 20px;
  font-weight: 600;
  box-shadow: 0 10px 35px rgb(0 0 0 / 7%) !important;
}
.accor__second__heading {
  font-size: 13px;
  margin-top: 3px;
  font-weight: 500;
}

.obligation__container {
  margin-bottom: 15px;
  box-shadow: 0 10px 35px rgb(0 0 0 / 7%);
  overflow: hidden;
  border-radius: 8px;
  .MuiAccordion-root {
    border: none;
    border-radius: 8px;
    .MuiAccordionSummary-root {
      box-shadow: 0 10px 35px rgb(0 0 0 / 7%);
      padding: 0px 10px;
      // border-left: 8px solid #023dff;
      border-radius: 10px;

      .sa__box {
        display: flex;
        flex-direction: column;
        font-size: 0.67rem;
        font-weight: 500;

        .sa__status {
          margin-bottom: 0.5rem;
        }
        .sa__action {
          color: red;
          font-weight: 700;
          font-style: italic;
        }
        .sa__btn {
          color: white;
          background-image: linear-gradient(180deg, #44a3ff 0%, #002bff 100%);
          padding: 4px 8px;
          border-radius: 10px;
          width: 48%;
          text-align: center;
          font-size: 10px;
        }
      }
    }
  }
}

.aev__heading {
  font-size: 17px;
  font-weight: 400;
  margin-top: 13px;
}
.aev__paras {
  font-weight: 400;
  font-size: 11px;
  margin: 0.5rem 0;
}
.aev__footer__paras {
  color: red;
  font-size: 11px;
  font-weight: 500;
}

.accor__header {
  display: flex;
  border-radius: 4px;
  box-shadow: 0 25px 99px rgba(0, 0, 0, 0.08);
  padding: 10px 4px;
  margin: 15px 0;
  justify-content: space-between;
  position: relative;
}
.accor__img {
  width: 100%;
  height: 100%;
  max-width: 70px;
}
.accor__background {
  border-radius: 10px;
  height: 70px;
  overflow: hidden;
}
.accor__second {
  font-size: 10px;
  margin-left: 14px;
}

.accor__third {
  position: absolute;
  width: 70px;
  text-align: center;
  right: 5px;
  top: 0;
  background-color: rgba(255, 0, 0, 0.159);
  color: red;
  padding: 7px 9px;
  border-radius: 0 0 10px 10px;
  font-size: 9px;
}
.accor__first {
  display: flex;
}
.accor__second__body {
  margin-top: 5px;
  color: #aeababe2;
  font-size: 11px;
  font-weight: 500;
}
.accor__body__one {
  margin-bottom: 5px;
}

.MuiAccordion-root.Mui-expanded {
  border-radius: 9px;
  border: 1px solid rgba(0, 0, 0, 0);
  box-shadow: 0 10px 35px rgb(0 0 0 / 7%);
  margin: 16px 0;
}
.MuiAccordion-root {
  position: relative;
  transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid rgba(128, 128, 128, 0.123);
  border-radius: 20px;
  box-shadow: 0 10px 35px rgb(0 0 0 / 7%);
}
.ac__steps__container {
  display: flex;
  justify-content: space-between;
  // margin: 0.8rem 0;
  width: 100%;
}

.ac__steps__arrow {
  max-width: 40px;
}
.ac__step {
  flex: 1;
  box-sizing: border-box;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(176, 177, 179, 0.26);
  position: relative;
  font-size: 9px;
  font-weight: bold;
  cursor: pointer;
  color: black;
  background: white;
  border-radius: 10px;
}
.ac__step1__img {
  width: 18px;
  height: 18px !important;
  position: absolute;
  bottom: -8px;
}

.accor__box1 {
  position: absolute;
  width: 70px;
  text-align: center;
  right: 5px;
  top: 0;
  background-color: rgba(255, 0, 0, 0.159);
  color: red;
  padding: 7px 9px;
  border-radius: 0 0 10px 10px;
  font-size: 9px;
}
.accor__box2 {
  width: 70px;
  text-align: center;
  background-color: #e4f7ff;
  color: #2e92b1;
  padding: 3px 0;
  text-align: center;
  border-radius: 12px;
  font-size: 9px;
  margin-top: 1.5rem;
}
.accor__box3__img {
  width: 15px;
  margin-top: 6px;
  height: 15px;
  margin-left: 46px;
}
.active__trade_tab {
  cursor: pointer;
  // background: linear-gradient(180deg, #44a3ff 0%, #002bff 100%);
  background: #002bff;
  color: white;
}
.disabled__trade_tab {
  background: lightgrey;
  color: black;
  pointer-events: none;
}
.trade__loader_box {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.trade__loader {
  width: 30px !important;
  height: 30px !important;
}
.disable__trade_tab {
  pointer-events: none;
  background-color: rgba(211, 211, 211, 0.486);
}
.cancel-btn {
  color: white;
  background-image: linear-gradient(180deg, #d99797 0%, #ff0000 100%);
  border: none;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
  margin-top: 10px;
  min-height: 45px !important;
}
