@font-face {
  font-family: "Avenir";
  src: local("Fonts"), url(./Fonts/Avenir-Medium.ttf) format("truetype");
}
* {
  margin: 0;
  padding: 0;
}
body {
  max-width: 100%;
  overflow-y: auto !important;
  margin: auto;
  position: relative;
  min-height: 100vh;
}
.App {
  width: 40%;
  margin: auto;
}
@media only screen and (max-width: 540px) {
  .body {
    max-width: 480px;
  }
}
@media only screen and (max-width: 540px) {
  .App {
    width: 100%;
  }
}
.MuiDrawer-root {
  max-width: 27%;
  overflow-y: auto !important;
  /* left:35% !important; */
  position: absolute !important;
  right: 0 !important;
  left: 0 !important;
  z-index: 999 !important;
}
@media only screen and (max-width: 540px) {
  .MuiDrawer-root {
    max-width: 480px;
    z-index: 1000 !important;
  }
}
.MuiDrawer-root > * {
  overflow-y: auto !important;
  max-width: 480px;
  /* left:35% !important; */
  position: absolute !important;
  right: 0 !important;
  left: 0 !important;
}

a {
  text-decoration: none;
  color: #246afe;
}
.progress {
  height: 45vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.progress .bar {
  font-size: 5rem;
}
.bot_btn {
  position: fixed;
  max-width: 480px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
