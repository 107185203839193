.Weblink__component {
  border: 1px solid black;
  display: flex;
  min-height: 35px;
  border-radius: 8px;
  overflow: hidden;
}
.Weblink__left {
  width: 4%;
  background-color: blue;
  /* border-radius: 8px; */
}
.Weblink__right {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  /* background-color: blue; */
}
.Weblink__component__link {
  color: blue;
  text-align: center;
}
