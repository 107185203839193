.popup1__container {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 15px;
  .popup__body2 {
    margin-top: 10px;
    margin-bottom: 15px;
    .body2__heading {
      text-decoration: underline;
      font-style: italic;
    }
    .body2__para3 {
      color: red;
      font-style: italic;
    }
  }
  .body1__heading {
    text-decoration: underline;
    font-style: italic;
  }
}
.popup__modal_parent {
  height: 100vh !important;
  width: 100vw !important;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}
.popup__modal_backdrop {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.655);
  backdrop-filter: blur(10px);
  position: absolute;
  top: 0;
  left: 0;
}
.popup__modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
  padding: 20px 5px;
  display: flex;
  flex-direction: column;
  width: 360px;

  @media screen and (max-width: 480px) {
    width: 95%;
  }
}
.popup__modal_closeicon {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 15px;
}
// ==========================================================
.popup2__container {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 15px;
  .body1__heading {
    text-decoration: underline;
    font-style: italic;
  }
  .body1__para3 {
    color: red;
    font-style: italic;
  }

  .popup__body2 {
    margin-top: 15px;
  }
}

// ===============================================================

.popup3__container {
  margin: 0 15px;
  font-size: 12px;
  .popup3__heading {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .popup3__Item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    width: 100%;
    .popup3__inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 5px;
      text-align: left;
      flex-grow: 1;
    }
    div {
      text-align: left;
    }
  }
  .popup3__body1 {
    margin-bottom: 10px;
  }
  .popup3__body2 {
    margin-bottom: 10px;
  }
  .popup3__body4 {
    margin-top: 5px;
  }
  .popup3__deatails__div {
    margin-bottom: 10px;
  }
}

.popup4__container {
  margin: 0 15px;
  font-size: 11px;
  .popup4__heading {
    text-decoration: underline;
    font-style: italic;
    margin-bottom: 2rem;
  }
  .popup4__body1 {
    margin-bottom: 10px;
  }
  .popup4__body2 {
    margin-bottom: 10px;
  }
  .popup4__body3 {
    margin-bottom: 10px;
  }
}
