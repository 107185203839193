.no__container {
  color: gray;
}
.no__btn {
  width: 70%;
  min-height: 45px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-image: linear-gradient(180deg, #44a3ff 0%, #002bff 100%);
  border: none;
  border-radius: 5px;
  padding: 5px;
}
.no__header {
  margin: 4rem 0;
  text-align: center;
  font-size: 20px;
}
.no__body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.no__body__para {
  font-size: 13px;
  text-align: center;
  margin: 10px 13px;

  margin-bottom: 4rem;
}
.no__footer {
  text-align: center;
  border-radius: 5px;
  font-size: 11px;
  padding: 15px 13px;
  /* background-color: rgba(128, 128, 128, 0.226); */
}
