.identity__info {
  display: flex;
  flex-direction: column;
}
.ii__title {
  color: gray;
}
.ii__nothing_found {
  width: 100%;
  text-align: center;
  padding: 5px 0px;
  color: lightgray;
}
