.allC__container {
  width: 90%;
  margin: auto;
  .allC__heading {
    font-size: 1.6rem;
    margin: 1rem 0;
    margin-bottom: 1.5rem;
  }

  .allC__accor {
    display: flex;
    flex-direction: column;
    gap: .7rem;
      .MuiAccordion-root {
        border: none;
        border-radius: 10px;
        box-shadow: none;
        box-shadow: 0px 0px 15px rgb(5 3 3 / 9%);
        &::before{
          opacity: 0;
        }
        .MuiAccordionSummary-root {
          border-radius: 10px;
          position: relative;

          .allC__box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: .5rem 0;

            .allC__clientname {
              font-size: 1.1rem;
              font-weight: bold;
            }
            .allC__icon2 {
              height: 12px;
              width: 12px;
              position: absolute;
              top: 40%;
              right: 18%;
              background-color: red;
              border-radius: 50rem;
            }
          }
        }
      }
      .MuiAccordionDetails-root {
        display: flex;
        padding: 0 1.2rem;
        margin-top: -22px;
        .allC__expanded__view {
          .allC__para {
            font-size: .8rem;
          }
          .allC__icons {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            margin-top: 5px;
            .allC__icon1 {
              height: 25px;
              // width: 15px;
              object-fit: contain;
              padding: 5px 13px;
              background-color: #c6e4ff;
              margin-top: 10px;
              margin-bottom: 7px;
              margin-right: 5px;
              border-radius: 8px;
            }
          }
        }
      }
  }
}
