.ordermod__heading {
  font-size: 23px;
}

.ordermod__header {
  position: relative;
  display: flex;
  border-radius: 10px;
  box-shadow: 0 3px 12px rgb(0 0 0 / 7%);
  background-color: #ffffff;
  padding: 10px 4px;
  margin: 15px 0;
  justify-content: space-between;
}
.ordermod__img {
  width: 80px;
  height: 100%;
  border-radius: 15px;
}
.img__background {
}
.ordermod__second {
  font-size: 10px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.om__thirdimg {
  height: 7px;
  width: 14px;
  margin-left: 2px;
}
.ordermod__third {
  font-size: 9px;
  background: #e4f7ff;
  position: absolute;
  top: 35%;
  right: 8px;
  display: flex;
  justify-content: center;
  padding: 0.3rem 0.7rem;

  color: #2e92b1;
  border-radius: 8px;
  box-sizing: border-box;
}
.om__expressimg {
  height: 7px;
  width: 7px;
  margin-left: 2px;
}
.ordermod__express {
  font-size: 9px;
  color: #fcc777;
  border-radius: 0 0 8px 8px;
  background-color: #fff8ed;
  position: absolute;
  top: 1%;
  right: 8px;
  display: flex;
  justify-content: center;
  padding: 0.4rem 1.26rem;
  box-sizing: border-box;
}
.ordermod__second .body__one {
  color: gray;
}
.second__heading {
  font-weight: 500;
  font-size: 11px;
}
.bi__body__para2 {
  color: gray;
}
/* .bi__body__para2 span {
  text-transform: lowercase;
}
.bi__body__para2 span::first-letter {
  text-transform: capitalize;
} */

@media only screen and (max-width: 400px) {
  .ordermod__third {
    font-size: 7px;
    padding: 0.3rem 0.3rem;
    top: 25%;
  }
  .ordermod__express {
    font-size: 7px;
    padding: 0.3rem 0.9rem;
  }
  .om__thirdimg,
  .om__expressimg {
    height: 6px;
    width: 12px;
  }
}
@media only screen and (max-width: 355px) {
  .second__heading {
    font-size: 8.5px;
  }
}
.ordermod__gaug,
.ordermod__fresh {
  font-size: 9px;
  position: absolute;
  right: 10px;
  display: flex;
  justify-content: center;
  padding: 0.3rem 0;
  border-radius: 8px;
  box-sizing: border-box;
}
.ordermod__gaug {
  top: 10px;
  background-color: #fff8e4;
  width: 80px;
  color: #b1802e;
}
.ordermod__fresh {
  width: 80px;
  background-color: #f1f1f1;
  top: 35px;
  color: #a7a7a7;
}
