.chosebank__confirm {
  font-size: 12px;
  display: flex;
  border: 1px solid grey;
  border-radius: 4px;
  margin: 10px 0;
}
.choseBank__container {
  width: 90%;
  margin: auto;
}
.choseBank__title{
  margin-bottom: .5rem;
}
.choseBank__title .heading{
  font-size: 1.3rem;
}
.chosebank__radio__confirm {
  margin-top: 8px;
  font-size: 10px;
  color: grey;
}
.chosebank__addbank {
  border: 1px solid grey;
  border-radius: 4px;
  display: flex;
  font-size: 10px;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  color: #44a3ff;
  margin-bottom: 50px;
  font-weight: bold;
}
.chosebank__img {
  height: 15px;
  width: 15px;
  margin-right: 5px;
}
.chosebank__addbank span{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 4px;
  margin: 0 .3rem;
  border: 2px solid #44a3ff;
  border-radius: 50rem;
  height: 8px;
  width: 13px;
  font-weight: bolder;
  font-size: .8rem;
}