.co__upper__green {
  background-color: #009c2a;
  color: white;
  font-size: 10px;
  position: relative;
  padding: 30px 10px;
  padding-bottom: 14px;
  border-radius: 8px;
}
.co__green__para1 {
  position: absolute;
  color: blue;
  background-color: white;
  padding: 5px 8px;
  top: 0.5px;
  border-radius: 0 0 6px 6px;
}
.co__para2__link {
  color: white;
  text-decoration: underline;
  position: absolute;
  bottom: 2px;
  right: 12px;
}
