.af__upper {
  width: 90%;
  margin: auto;
}
.af__heading {
  font-size: 28px;
}
.af__heading span {
  color: #0766e2;
}

.af__heading__para {
  font-size: 11.7px;
  margin-bottom: 10px;
}
.af__heading__para2 {
  font-size: 12px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px#0000004a;
  padding: 5px 12px;
}

.af__expressdeal__component {
  margin: 30px 0;
}
.af__watch {
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(128, 128, 128, 0.248);
  padding: 10px 0px;
  color: gray;
  border-radius: 8px;
  margin-bottom: 25px;
}
.af__watch1 {
  font-size: 11px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(128, 128, 128, 0.138);
  padding: 10px 17px;
  color: rgb(128, 128, 128);
  border-radius: 8px;
  margin: 25px 0;
}
.af__footer__para {
  font-size: 12px;
  margin-bottom: 13px;
  text-align: center;
  color: gray;
}
