.slide__wrapper {
  width: 100%;
  box-sizing: border-box;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-x: hidden;
  position: relative;
}
.slide__item {
  text-transform: capitalize;
  font-size: 14px;
  padding: 6px 18px;
  box-sizing: border-box;
  border-radius: 40px;
  width: fit-content !important;
  border: 1px solid rgba(70, 68, 68, 0.089);
  margin: 0px 8px !important;
  cursor: pointer;
}
.active {
  background-image: linear-gradient(180deg, #44a3ff 0%, #002bff 100%);
  color: white;
  border: none;
}
