.search__not_found {
  height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.snf__title {
  font-weight: bold;
  font-size: 18px;
}
.snf__desc1 {
  text-align: center;
  padding: 0px 25px;
  box-sizing: border-box;
  color: grey;
}
.snf__desc2 {
  box-sizing: border-box;
  text-align: center;
  color: grey;
}
.snf__btn {
  width: 245px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #007aff;
  border-radius: 5px;
}
.snf__wrapper {
  height: 80vh;
  display: flex;
  align-items: center;
}
