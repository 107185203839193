.menu_container {
  overflow: hidden;
  width: 100%;
}

.menu_nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
}
.toggle_icon {
  height: 40px;

  @media only screen and(max-width:430px) {
    height: 35px;
  }
}
.menu_nav_search {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-left: 30px;
}
.search_icon {
  height: 25px;

  @media only screen and(max-width:430px) {
    height: 20px;
  }
}
.search_bar {
  border-bottom: 2px solid rgba(105, 100, 100, 0.6);
  border-radius: 2px;
  display: flex;
  gap: 0.3rem;
  align-items: center;
  padding: 0.3rem;
}
.inp_title {
  margin-left: 10px;
}
.search_input {
  border: none;
  width: 100%;
  font-size: 1rem;
  outline: none;

  @media only screen and(max-width:430px) {
    font-size: 0.8rem;
  }
}
.filter_icon {
  height: 30px;

  @media only screen and(max-width:430px) {
    height: 25px;
  }
}
.notification_btn {
  .count {
    position: absolute;
    height: 16px;
    width: 16px;
    top: 8%;
    right: 28%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 0.75rem;
    background-color: #44a3ff;
    border-radius: 50rem;

    @media only screen and(max-width:430px) {
      height: 13px;
      width: 13px;
      font-size: 0.6rem;
    }
  }
}
.notification_icon {
  height: 30px;
}

.menu_box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  padding: 2rem 0rem 0rem 0.5rem;

  button {
    max-width: 200px;
  }
}
@media only screen and(max-width:540px) {
  .menu_box {
    padding: 10px 0;
  }
  .avater_box {
    margin: 0 10px !important;
  }
  .menu_data {
    margin: 0 2.5rem !important;
  }
}
.menu_close {
  position: absolute !important;
  right: 10px;
  top: 10px;
  cursor: pointer;
  z-index: 5;
}

.avater_box {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0 1rem;
  font-family: "Roboto", sans-serif;
}
.avater {
  border-radius: 100%;
  padding: 7% 7%;
  background-color: #d2eaff !important;
}
.add_ellipses {
  @media only screen and(min-width:600px) {
    font-size: 19.2px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.avater_title {
  font-size: 1.5rem;
  font-weight: bold;

  a {
    color: #000;
  }

  @media only screen and(max-width:450px) {
    font-size: 1.3rem;
  }
  @media only screen and(max-width:390px) {
    font-size: 1rem;
  }
}
.menu_data {
  display: flex;
  flex-direction: column;

  margin: 0 3rem;

  img {
    height: 22px;
    width: 22px;
  }

  .nav_link {
    color: black;
  }
  .active_nav_link {
    color: blue;
  }
}
.inp {
  display: flex;
  align-items: center;
  margin: 10px 0px;
  font-size: 1.2rem;
}
.ref_box {
  margin: 0 1rem;
}
.ref_title {
  color: #70b8ff;
}

.sidebar-button {
  padding: 10px;
  border-radius: 5px;
  font-weight: 700;
  color: #fff;
  width: 100%;

  &.refer-button {
    background: #0d1fe6;
    &:hover {
      background-color: #4a4fe4;
    }
    &:active {
      background-color: #1507d9;
    }
  }
  &.log-in {
    background: #0dbbe6;
    &:hover {
      background-color: #60acf3;
    }
    &:active {
      background-color: #074dd9;
    }
  }

  &.log-out {
    background: #ff0909;
    &:hover {
      background-color: #d82e2e;
    }
    &:active {
      background-color: #9f0b0b;
    }
  }
}

.becomepartner_box {
  margin: 0px 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: larger;
  padding-bottom: 20px;
}
.partner {
  cursor: pointer;
  a {
    color: #2a6eff;
  }
}
.signup_icon {
  font-size: 2rem;
  color: #54abff;
}

.refer_style {
  background-color: #2574ff !important;
  padding: 0.7rem 2rem !important;
  margin: ".5rem 0" !important;
  color: white !important;
  font-size: 1.2rem !important;
  margin-top: 10px !important;
}
.margin_1rem {
  margin-right: 1.2rem;
}
.fontweight_500 {
  font-weight: 500;
}
.ref_detail {
  margin-top: 5px;
  margin-bottom: 5px;
}
