.otp__screen {
  width: 480px;
  height: 87vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;

  .btn {
    max-width: 480px;
    width: 100%;
    display: none;
    position: fixed;
    max-width: 480px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    @media (min-width: 500px) {
      display: block;
    }
  }
}
.opt__body {
  width: 90%;
  flex: 1;
}

.otp-input {
  border-radius: 5px;
  border: 1px solid #ccc;
  display: flex;
  height: 40px;
  gap: 10px;
  align-items: center;
  padding: 5px 5px 5px 15px;
  input {
    border: none;
    width: 100%;
    outline: none;
    &:disabled {
      opacity: 0.5;
    }
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .paste-btn {
    display: none;
    align-items: center;
    justify-content: center;
    height: 35px;
    aspect-ratio: 1/1;
    background-image: linear-gradient(180deg, #44a3ff, #002bff);
    color: #fff;
    border-radius: 5px;
    &:disabled {
      opacity: 0.5;
      background-color: #ccc !important;
      background-image: none;
      color: #333 !important;
    }
    @media (max-width: 500px) {
      display: flex;
    }
  }
}

.otp__heading {
  margin-top: 80px;
  span {
    color: #0766e2;
  }
}
.otp__msg {
  margin-top: 80px;
  color: gray;
  font-size: 13px;
  margin-bottom: 28px;
  padding-left: 5px;
}

.resend__text {
  margin-top: 80px;
  margin-bottom: 10px;
  color: gray;
  width: 100%;
  text-align: center;
  font-size: 12px;
}
.resend__btn {
  margin-top: 80px;
  margin-bottom: 10px;
  text-align: center;
  width: 100%;
  color: grey;
  font-weight: bold;
}
.otp__wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 5px;
}
.opt__box {
  border: none;
  border-bottom: 1px solid gray;
  width: 40px !important;
  font-size: 19px;
}
.opt__box:focus {
  color: green;
  outline: none;
}
.resend__otps {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wrong__otp {
  color: red !important;
  border-color: red;
}
@media (max-width: 480px) {
  .otp__screen {
    width: 100%;
  }
}
