.modo__upper {
  width: 90%;
  margin: auto;
}

.modo__heading {
  font-size: 28px;
  span {
    color: #0766e2;
  }
}

.modo__upper {
  width: 90%;
  margin: auto;
}
.modo__heading {
  font-size: 28px;
  margin-top: 17px;
}
.modo__paras {
  font-size: 12px;
  margin: 6px 0;
}
.modo__checkbox__para {
  font-size: 8.5px;
  width: 60%;
  margin-left: 26px;
  color: grey;
}
.modo__checkbox__component {
  margin-top: 20px;
}
.modo__footer__para1 {
  font-size: 14px;
  margin: 20px 0;
  background-color: rgba(248, 0, 0, 0.171);
  padding: 9px 8px;
  border: 1px solid red;
  font-weight: 500;
  border-radius: 10px;
  color: red;
}
.modo__footer__para2 {
  font-size: 10px;
  text-align: center;
  margin-bottom: 8px;
}

.mo__checkbox__component {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.mo__checkbox__para1 {
  margin-top: 0.4rem;
  font-size: 8.5px;
  width: 60%;
  color: grey;
}
.mo__checkbox__para2 {
  font-weight: bold;
  color: black;
  font-size: 0.6rem;
  margin: 0.1rem 0;
}

.modo__paras {
  .per {
    border-bottom: 1px solid blue;
    color: blue;
  }
  input {
    color: blue;
    text-align: center;
    border: none;
    width: 38px;
    outline: none;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
