.cc__upper {
  width: 90%;
  margin: auto;

  .heading{
    margin-top: 2rem;
    font-size: 1.4rem;

    span{
      color: blue;
    }
  }
}

.cc__message__icon {
  width: 90px;
  height: 90px;
}
.cc__img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4rem 0;
}

.cc__para1 {
  font-size: 13px;
  margin-bottom: 2rem;
}

.cc__alert {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;

  .linksent {
    border: 1px solid #c1c1c1;
    padding: 1rem;
    border-radius: 10px;
    display: flex;
    gap: 0.6rem;
    align-items: center;
    img {
      height: 1.5rem;
    }
    p {
      font-size: 0.8rem;
    }
  }
  .linkcopy {
    border: 1px solid #c1c1c1;
    padding: 0.7rem 1rem;
    border-radius: 10px;
    display: flex;
    gap: 0.6rem;
    align-items: center;

    a {
      font-size: 0.8rem;
    }
    .copyimg {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      img {
        height: 1.2rem;
      }
      p {
        font-size: 0.7rem;
      }
    }

    @media only screen and (max-width: 400px) {
      a {
        font-size: 0.65rem;
      }
      .copyimg {
        img {
          height: 1rem;
        }
        p {
          font-size: 0.6rem;
        }
      }
    }
  }
}
