.sf__upper {
  width: 90%;
  margin: auto;
}

.sf__bottom__header {
  display: flex;
}
.sf__bottom__header span {
  color: #0766e2;
}

.sf__para {
  font-size: 14px;
  margin-left: 3px;
  color: grey;
}
.sf__heading {
  font-size: 28px;
  margin: 20px 0;
}

.sf__upper__para1 {
  font-size: 12px;
  border-radius: 10px;
  /* box-shadow: 0px 0px 5px 0px#0000004a; */
  background-color: rgba(128, 128, 128, 0.097);
  color: gray;
  padding: 15px 12px;
}
.sf__expressDealComponent {
  margin: 15px 0;
}
.sf__watch {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(128, 128, 128, 0.131);
  padding: 10px 0px;
  color: gray;
  border-radius: 8px;
  margin-bottom: 3rem;
  margin-top: 1.5rem;
}
.sf__checkbox__component {
  margin-top: 0px;
  margin-bottom: 7px;
  display: flex;
}
.sf__para2 {
  font-weight: bold;
  font-size: 14px;
  color: black;
}
.sf__filable__qty {
  font-size: 14px;
  border-radius: 10px;
  background-color: rgba(128, 128, 128, 0.138);
  padding: 15px 12px;
  position: relative;
}
.sf__para2__link {
  position: absolute;
  bottom: 10px;
  right: 12px;
}
