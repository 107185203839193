.alert__box {
  width: 90%;
  height: 60px;
  border: 1px solid #c1c1c1af;
  display: flex;
  align-items: center;
  max-width: 460px;
  border-radius: 10px;
  position: fixed;
  background-color: white;
  top: 15px;
  margin: auto;
  margin-left: 5px;
}
.alert__icon {
  width: 25px;
  height: 25px;
  margin-left: 20px;
}
.alert__text {
  font-size: 14px;
  font-weight: bold;
  margin-left: 10%;
  flex: 1;
}
.alert__close {
  position: absolute;
  font-size: 20px !important;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
@media (max-width: 400px) {
  .alert__text {
    margin-left: 2%;
  }
}
