.ap__whatsapp_logo {
  width: 50px;
  height: 50px;
  align-self: center;
  margin: 75px 0;
  margin-bottom: 10px;
}
.ap__confirm_text2 {
  width: 100%;
  font-size: 13px;
  text-align: center;
  color: gray;
  margin-bottom: 55px;
}

.success_main_align{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
