.dashboard__wrapper {
  width: 480px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-bottom: 100px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  background: rgba(211, 211, 211, 0.301);
}
.dashboard__wrapper::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.dashboard__wrapper::-mos-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.dashboard__header {
  width: 480px;
  background-color: white;
}
.dummy__header {
  height: 80px;
}
.dashboard__menu_wrapper {
  max-width: 480px;
  position: fixed;
  top: 0;
  background-color: white;
  z-index: 999;
}
.dashboard__menu_wrapper {
  width: 100%;
}

.db__main {
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  padding: 10px;
  min-height: 65vh;
}
.db__main_empty {
  min-height: 60vh;
  display: flex;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: grey;
  font-weight: bold;
  text-align: center;
}
.pagination {
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.pagination .active {
  width: 20px;
  display: flex;
  justify-content: center;
}
.dashboard__page_logo {
  z-index: 100;
  top: 0px;
  width: 100%;
  background-color: white;
}
.dashboard__page_logo > img {
  object-fit: cover;
  object-position: left;
  height: 30px;
  max-width: 160px;
  /* width: auto; */
  /* border: 2px solid #000; */
}
#bottomLoader {
  width: 100%;
  text-align: center;
  padding: 10px 0px;
}
.dashboard__loader {
  color: grey !important;
  width: 22px !important;
  height: 22px !important;
}
@media (max-width: 480px) {
  .dashboard__wrapper {
    width: 100%;
  }
  .dashboard__header {
    width: 100%;
  }
}
