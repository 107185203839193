.ordermod__container {
  padding: 0 12px;
}

.ordermod__heading {
  font-size: 23px;
  span {
    color: #002bff;
  }
}

.ordermod__header {
  display: flex;
  border-radius: 4px;
  box-shadow: 0px 0px 5px 0px#0000004a;
  padding: 10px 4px;
  margin: 15px 0;
}
// .ordermod__second {
//   font-size: 10px;
//   margin-left: 22px;
// }

.om_para {
  font-size: 11px;
  margin-top: 3px;
  color: #000000a6;
  display: flex;
}

.ordermod__footer {
  background: #ff00003d;
  font-size: 11px;
  margin: 20px 0;
  color: red;
  border: 1px solid red;
  padding: 5px;
  border-radius: 8px;
}

.ordermod__btns {
  margin-top: 40px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden;
}
.ordermod__btn1 {
  color: white;
  background-image: linear-gradient(180deg, #44a3ff 0%, #002bff 100%);
  border: none;
  /* border-radius: 5px; */
  padding: 5px;
  width: 50%;
  min-height: 45px !important;
}
.ordermod__btn2 {
  color: white;
  background-image: linear-gradient(180deg, #d99797 0%, #ff0000 100%);
  border: none;
  // border-radius: 5px;
  padding: 5px;
  width: 50%;
  min-height: 45px !important;
}
.om__ordervalidity__component {
  margin-top: 32px;
}

.confirmorder_box {
  display: flex;
  flex-direction: column;
  gap: 6rem;

  .heading {
    margin: 0 1rem;

    div {
      font-size: 1.35rem;
    }
    p {
      font-size: 0.8rem;
      margin: 0.6rem 0.5rem;
    }
  }
  button {
    color: white;
    background-image: linear-gradient(180deg, #d99797 0%, #ff0000 100%);
    border: none;
    padding: 5px;
    min-height: 45px !important;
    width: 100%;
    border-radius: 0.55rem;
  }
}
