.oe__upper {
  width: 90%;
  margin: auto;
}

.oe__Quote {
  border-radius: 15px;
  margin-top: 5px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
}
.oe__quote__hading {
  padding: 8px 10px;
  color: #111111;
  font-size: 1.06rem;
}
.oe__quote__body {
  padding: 0px 10px;
  padding-bottom: 5px;
  display: flex;
  font-size: 11px;
  justify-content: space-between;
}

.oe__Quote__fotter {
  font-size: 9px;
  background-color: #f5f5f5;
  padding: 17px 10px;
  display: flex;
  justify-content: space-between;
}
.oe__body__para1 {
  color: gray;
}
.oe__footer__para1 {
  color: gray;
}
.oe__component {
  margin: 30px 0;
}
.oe__footer__para {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  color: #5d5d5d;
}
.oe__btns {
  background-color: #fff;
  margin-top: 4px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden;
}
.oe__btn1 {
  color: white;
  background-image: linear-gradient(180deg, #44a3ff 0%, #002bff 100%);
  border: none;
  /* border-radius: 5px; */
  padding: 5px;
  width: 50%;
  min-height: 45px !important;
}
.oe__btn2 {
  color: white;
  background-image: linear-gradient(180deg, #d99797 0%, #ff0000 100%);
  border: none;
  /* border-radius: 5px; */
  padding: 5px;
  width: 50%;
  min-height: 45px !important;
}

.oe__btn1:disabled,
.oe__btn2:disabled {
  opacity: 0.5;
}

.oe__lower {
  position: fixed;
  max-width: 480px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}
