.obligation__box {
  display: flex;
  flex-direction: column;
  padding: 25px 8px;
  position: relative;
  /* height: 150px; */
}
.ob__box_heading {
  font-weight: 500;
  font-size: 22px;
  padding-top: 5px;
}
.ob__box_action {
  font-weight: 700;
  font-style: italic;
  color: red;
}
.ob__box_noaction {
  font-weight: 700;
  font-style: italic;
  padding-top: 10px;
  color: blue;
}
.ob__box_btn {
  color: white;
  margin: 10px 0px;
  border: none;
  cursor: pointer;
  background-color: #234dd6;
  height: 25px;
  display: flex;
  max-width: fit-content;
  padding: 0 25px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
}
.ob__box_date {
  color: red;
  font-weight: 500;
  margin-bottom: 10px;
}
.ob__box_bottom {
  background-color: #0f25b6;
  border: none;
  width: auto;
  max-width: 100px;
  color: #fff;
  padding: 5px 10px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 11px;
  font-weight: 600;
  margin-top: 0.7em;
}

.ob__box_bottom:hover {
  background-color: #3a3c46;
}
