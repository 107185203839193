.succmodi_container {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;

  .succ {
    margin-top: 3rem;
    border: 1px solid #c1c1c1;
    padding: 1rem;
    border-radius: 10px;
    display: flex;
    gap: 0.6rem;
    align-items: center;
    img {
      height: 1.5rem;
    }
    p {
      font-size: 0.8rem;
    }
  }
  .detail {
    font-size: .8rem;
    color: gray;
    padding: 0 .3rem;
  }
}
