.added__container {
  margin: 15px;
  width: 90%;
}
.addedbank__header {
  border: 1px solid #00000057;
  display: flex;
  min-height: 40px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}
.addedbank__left {
  width: 4%;
  background-image: linear-gradient(180deg, #44a3ff 0%, #002bff 100%);
  /* border-radius: 8px; */
  display: flex;
  text-align: center;
  align-items: center;
  color: white;
  justify-content: center;
}
.addedbank__right {
  width: 95%;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  line-height: 20px;
  margin: 8px 8px;
}
.ad__para1 {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}
.addeDemat__thirdimg {
  height: 20px;
  width: 10px;
  margin-left: 2px;
}
.ad__p1name {
  font-weight: bolder;
}
.ad__delbtn {
  margin-right: 10px;
  font-weight: 600;
  color: #000000ab;
}
.ad__delbtn_disabled {
  margin-right: 10px;
  font-weight: 600;
  color: #868282ab;
  pointer-events: none;
}

.ad__para2 {
  display: flex;
  font-size: 12px;
  margin-bottom: 5px;
}
.ad__p2name {
  font-weight: 600;
  color: #000000ab;
  margin-right: 100px;
}
.addd__active {
  color: green;
  padding-right: 5px;
}
.ad__cmr {
  background-color: white;
  padding: 0 11px;
  border-radius: 30px;
  font-size: 9px;
  text-decoration: underline;
  box-shadow: 0px 0px 7px -1px #00000040;
  color: blue;
  position: absolute;
  right: 13px;
  top: 53px;
}
.ad__p4name {
  font-weight: 600;
  color: #000000ab;

  font-size: 12px;
}
