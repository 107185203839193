.ao__container {
  width: 90%;
  margin: auto;
}

.ao__haeding {
  font-size: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  img {
    height: 20px;
  }
}
.ao__footer__para {
  flex-direction: row;
  color: gray;
  font-size: 12px;
  text-align: center;
}

.ordermod__heading {
  font-size: 23px;
}

.order_list_container {
  max-height: calc(100vh - 200px);
  border-radius: 10px;
  padding: 10px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 5px;
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    width: 5px;
    background: #ccc;
    border-radius: 50px;
  }
}

.ao__header {
  display: flex;
  border-radius: 4px;
  box-shadow: 0 25px 99px rgba(0, 0, 0, 0.08);
  padding: 10px 4px;
  margin: 15px 0;
  justify-content: space-between;
  position: relative;
}
.ao__img {
  width: 100%;
  height: 100%;
  max-width: 70px;
}
.ao__background {
  border-radius: 10px;
  max-width: 70px;
  height: 70px;
  overflow: hidden;
}
.orderCardSellAction {
  color: #ff5b59;
  font-weight: 500;
}
.orderCardBuyAction {
  color: #7ad581;
  font-weight: 500;
}
.ao__second {
  font-size: 10px;
  padding-left: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.ao__second__heading {
  font-size: 12px;
}
.ao__body__one {
  font-size: 11px;
}
.ao__second__body {
  min-height: 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ao__third {
  position: absolute;
  width: 70px;
  text-align: center;
  right: 5px;
  top: 0;
  background-color: rgba(255, 0, 0, 0.159);
  color: red;
  padding: 7px 9px;
  border-radius: 0 0 10px 10px;
  font-size: 8px;
  font-weight: bold;
}
.ao__first {
  display: flex;
}
.ao__thirdimg {
  height: 7px;
  width: 14px;
  margin-left: 2px;
}
.unopened__toast {
  background-color: blue;
}

.MuiDialog-paper {
  width: 410px !important;
  max-width: 410px !important;
  .dialog__content {
    .dialog__heading {
      font-weight: 700;
      color: #007dfa;
      font-size: 22px;
      // margin-bottom: 1rem;
    }
    .dialog__autocommplete {
      margin: 1rem 0;
      margin-bottom: 2rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
    .btn {
      background-image: linear-gradient(180deg, #44a3ff 0%, #002bff 100%);
      color: white;
      padding: 8px 10px;
      text-align: center;
      border-radius: 5px;
    }
  }
}
