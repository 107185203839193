.ifc__upper {
  width: 90%;
  margin: auto;
}
.ifc__inputs {
  display: flex;
  margin: 10px 0;
}
.ifc__input1__para {
  margin: 0 7px;
  margin-top: 10px;
  font-size: 18px;
}

.ifc__btn1 {
  border: 1px solid black;
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  margin: 35px 0;
}

.ifc__btn1__left {
  width: 4%;
  background-color: blue;
  margin-right: 12px;
}
.ifc__btn1__right {
  width: 95%;
  padding: 6px 0;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.ifc__btn__para {
  text-align: center;
  font-size: 13px;
  margin-top: 7px;
  color: gray;
}
.ifc__body {
  margin: 1.5rem 0;
  font-size: 12px;
  color: gray;
  line-height: 17px;
}
.ifc__body__para2 {
  margin: 1rem 0;
}
.ifc__watch {
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(128, 128, 128, 0.124);
  padding: 10px 0px;
  color: gray;
  border-radius: 8px;
  margin-bottom: 25px;
  margin: 2rem 0;
}

.ifc__watch span {
  color: #0766e2;
}
