.Addlogo__container {
  width: 480px;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-bottom: 100px;
}
.Addlogo__header {
  width: 90%;
  align-self: center;
}
.header__heading {
  display: flex;
  flex-direction: column;
  font-size: 25px;
}
.header__heading span {
  color: #0766e2;
}
.Addlogo__footer {
  width: 99.7%;
  display: flex;
  justify-content: center;
}
.Addlogo__btnns {
  width: 95%;
  border: 3px solid #007aff;
  border-radius: 15px;
  position: fixed;
  overflow: hidden;
  z-index: 200;
  bottom: 1vh;
  max-width: 475px;
}
.addlogo__btn1 {
  background-color: #007aff;
  color: white;
  border-radius: 8px;
  height: 35px;
  border: none;
  width: 100%;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.footer__logo {
  margin-left: 15px;
}
.footer__text {
  margin-left: 60px;
}
.btn2__or {
  margin-right: 15px;
  color: rgba(0, 0, 0, 0.527);
}
.Addlogo__title {
  border: 1px solid blue;
  margin: 8px 0;
  width: 70%;
  padding: 8px;
  border-radius: 5px;
  margin-left: 18px;
}

.Adlogo__main {
  border: 10px;
  width: 100%;
  background: rgba(211, 211, 211, 0.301);
  height: fit-content;
  box-sizing: border-box;
  padding: 10px;
}

.Addlogo__body {
  /* margin: 0 17px; */
  box-shadow: 0px -3px 7px -6px black;
  display: flex;
  margin-top: 10px;
  flex-direction: column;
}
.addlogo__btn2 {
  height: 35px;
  border: none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo__input {
  display: none;
}
.dashboard__screenshot {
  filter: grayscale(100%);
}
@media (max-width: 480px) {
  .Addlogo__container {
    width: 100%;
  }
}
