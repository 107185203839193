.nb__container {
  width: 90%;
  margin: auto;
}
.nb__btn1 {
  color: white;
  background-image: linear-gradient(180deg, #44a3ff 0%, #002bff 100%);
  border: none;
  border-radius: 10px;
  padding: 5px;
  width: 90%;
  min-height: 45px !important;
  margin-right: 10px;
}
.nb__btn2 {
  color: white;
  background-image: linear-gradient(180deg, #d99797 0%, #ff0000 100%);
  border: none;
  border-radius: 10px;
  padding: 5px;
  width: 90%;
  min-height: 45px !important;
}
.nb__buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.nb__button1 {
  width: 100%;
}
.nb__button2 {
  width: 100%;
}
.nb__btn1__para {
  text-align: center;
  color: gray;
  margin-top: 5px;
  font-size: 13px;
}

.nb__link {
  border: 1px solid black;
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  margin: 35px 0;
}

.nb__link____left {
  width: 4%;
  background-color: blue;
  margin-right: 12px;
}
.nb__link__right {
  width: 95%;
}
.nb__link__para {
  /* text-align: center; */
  font-size: 15px;
  margin: 7px 0;
  color: gray;
}
.nb__link__para span {
  color: #0766e2;
}

.nb__footer__para {
  font-size: 15px;

  color: gray;
}
.nb__footer__para2 {
  margin: 2rem 0;
  font-size: 11px;
  align-items: center;
  justify-content: center;
  background-color: rgba(128, 128, 128, 0.11);
  color: gray;
  padding: 1rem;
  border-radius: 10px;
}
