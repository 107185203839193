.alE__container {
  width: 90%;
  margin: auto;
  max-height: 90vh;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 5px;
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    width: 5px;
    background: #ccc;
    border-radius: 50px;
  }

  .alE__heading {
    font-size: 1.6rem;
    margin: 1rem 0;
  }
  .alE__header {
    display: flex;
    justify-content: space-between;
    margin-right: 15px;
    .alE__header__1st {
      .alE__header__para1 {
        font-size: 0.87rem;
        color: #878787;
      }
      .alE__header__para2 {
        font-size: 0.95rem;
        font-weight: bold;
      }
    }
  }
  .alE__body {
    .alE__body__heading {
      font-size: 19px;
      margin: 1.5rem 0;
      margin-bottom: 10px;
    }
  }
  .alE__details {
    display: flex;
    padding: 10px 0;
    justify-content: space-between;
    border-bottom: 2px solid rgb(243, 243, 243);
    margin-top: 1rem;
    .alE__details__2nd {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-right: 1.5rem;
      .alE__details__para1 {
        font-weight: 700;
        font-size: 13px;
      }
      .alE__details__para2 {
        background-color: #00a8ff;
        border: none;
        color: #fff;
        display: inline;
        padding: 5px 10px;
        border-radius: 50px;
        cursor: pointer;
        font-size: 11px;
        font-weight: 600;
        margin-top: 0.7em;
      }
    }
    .alE__details__1st {
      color: gray;
      .alE__details__para4 {
        font-size: 11px;
        margin-top: 0.7em;
      }
      .alE__details__para3 {
        font-weight: bold;
        color: #000;
        font-size: 15px;
        width: 10rem;
      }
    }
  }
}

.earnings-header {
  position: sticky;
  padding: 10px 0;
  top: 0;
  background-color: #fff;
  border-bottom: 1px solid #ccc;

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .download-btn {
    background-color: #00a8ff;
    border: none;
    color: #fff;
    display: inline;
    padding: 5px 10px;
    border-radius: 50px;
    cursor: pointer;
    font-size: 11px;
    font-weight: 600;
    margin-top: 0.7em;
    margin-right: 10px;
  }
}
