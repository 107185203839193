.profile__screen {
  width: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile__header {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}
.pf__header_left {
  font-size: 25px;
  color: #111111;
  font-weight: 300;
}
.pf__header_right {
  display: flex;
  flex-direction: column;
  font-size: 12px !important;
}
.pf__acc_status {
  color: rgba(109, 103, 103, 0.6);
  font-weight: 500;
}

.profile__body {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px 10px;
  box-sizing: border-box;
  min-height: 300px;
  justify-content: space-between;
}
@media (max-width: 480px) {
  .profile__screen {
    width: 100%;
  }
}
@media (max-width: 350px) {
  .pf__acc_status,
  .pf__acc_info {
    font-size: 12px;
  }
}
