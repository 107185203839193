.table__wrapper {
  width: 100%;
  display: flex;
  border: 1px solid #c1c1c1;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 10px 0px;
  border-left: none;
}
.table__body {
  display: grid;
}
.table__heading {
  padding: 10px 7px;
  background-color: rgba(241, 241, 241, 0.6);
  display: flex;
  align-items: center;
  border-left: 1px solid rgba(128, 128, 128, 0.568);
  font-size: 14px;
  font-weight: 400;
}
.table__data {
  padding: 5px 7px;
  display: flex;
  justify-content: center;
  border-left: 1px solid rgba(128, 128, 128, 0.568);
  font-size: 13px;
  color: grey;
  box-sizing: border-box;
  line-height: 20px;
}
