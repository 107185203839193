.mi__uper {
  width: 90%;
  margin: auto;
}
.mi__upper__heading {
  font-size: 23px;
  margin: 13px 0;
}
.mi__upper__heading span {
  color: #0766e2;
}

.mi__paras {
  font-weight: 500;
  font-size: 16px;
}
