.search__bar1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.search__box1 {
  position: relative;
  flex: 1;
  display: flex;
}
.search__input1 {
  width: 100%;
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 22px;
  outline: none;
  background-color: transparent;
}
.bars__icon1 {
  display: block;
  aspect-ratio: 1;
  padding-right: 5px;
}

.bars__icon1 img {
  width: 30px;
  height: 30px;
  display: block;
  object-fit: cover;
}

.search__close1 {
  position: absolute;
  top: 50%;
  right: 0px;
  cursor: pointer;
  transform: translateY(-50%);
}
