.dd__upper {
  width: 90%;
  margin: auto;
}

.dd__heading {
  font-size: 27px;
}

.dd__heading__para {
  font-size: 13px;
  margin: 8px 0;
  margin-bottom: 40px;
}

.dd__tf__hading {
  font-size: 15px;
  color: grey;
  margin-bottom: 15px;
}
.dd__body {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.dd__textfield1 {
  /* margin: 18px 0; */
}
.dd__confirm {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  font-size: 12px;
  border: 1px solid #8080806e;
  border-radius: 5px;
  margin: 13px 0;
}
.dd__checkbox__component {
  margin-top: 5px;
  /* margin-bottom: 105px; */
  display: flex;
}
.dd__container .MuiFormControlLabel-root {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border: 1px solid #dedede;
  margin-left: 0;
  border-radius: 10px;
  margin-bottom: 10px;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  font-size: 2.9rem;
}
.dd__container .MuiTypography-body1 {
  font-size: 0.9rem !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  color: grey;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.pageLoading {
  min-height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  gap: 10px;
  left: 0;
  flex-direction: column;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0 0 0 / 80%);
  z-index: 9999999999;
  p {
    color: #fff;
    font-weight: 600;
  }
}

.fail_container {
  margin: 0 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .fail_heading {
    font-size: 1.2rem;
    span {
      color: blue;
    }
  }
  .fail_detail {
    font-size: 0.9rem;
    span {
      font-weight: bold;
    }
  }
  .fail_disc {
    font-size: 0.85rem;
  }
}
.success_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  img {
    width: 15%;
  }
}
